import { Box, Button, Checkbox, FormControl, FormHelperText, Grid, Link, makeStyles, MenuItem, Select, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { caseTransfer, getCasesToBeTransfer} from "../API/TdsatApi";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import DatePickerTDSAT from "../components/DatePickerTDSAT";
import CourtNumberInput from "../components/CourtNumberInput";
import validateDateAndFormat from "utils/validateDateAndFormat";
import { useSnackbar } from "contexts/SnackbarContext";

const useStyles = makeStyles((theme) => ({
  root: {
		// padding: '1.5rem',
    width: '100%',
    boxSizing: 'border-box',
	},
  label: {
    color: '#121212',
    fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: '1.3125rem',
    textAlign: 'left',
    marginBottom: '0.2rem',
  },
  button: {
    padding: '0.4rem 2rem',
    borderRadius: '0.4rem',
    marginTop: '1.5rem',
    [theme.breakpoints.down("md")]: {
			padding: '0.6rem 2rem',
		},
  },
  tableHeadItem:{
		color: 'grey',
		fontSize: '15px',
		fontFamily: 'Satoshi-Bold',
	},
	tableRowValue: {
		color: '#858D98',
		fontSize: '14px',
	},
	valuePaper: {
		padding: "1.5rem 8px",
		cursor: "pointer",
		boxShadow: "none",
		width: "100%",
		border: "1px solid #E0E0E0",
		// "&:hover": {
		// 	background: "#E5F6EF",
		// 	'& $downloadButton': {
		// 		visibility: 'visible',
		// 	}
		// },
		[theme.breakpoints.down("sm")]: {
			textAlign: "center",
		},
	},
  formHeading: {
    fontSize: '1.375rem',
    fontFamily: 'Satoshi-Bold',
    color: theme.palette.primary.main,
    textAlign: 'left',
  },
}));

export default function CaseTransfer({handleClickOnNavItem}) {
  const classes = useStyles();
  const { showSnackbar } = useSnackbar();

  const [formData, setFormData] = useState({
    listing_date: null,
    benchNature: '',
    courtNumber: '',
    alocation_date: null,
    benchNatureTransferTo: '',
    courtNumberTransferTo: '',
  });
  const [cases, setCases] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [selectedCases, setSelectedCases] = useState([]);

  const headList = [
    'For Pronouncement of Judgment',
    'To be Mentioned',
    'For Preliminary Hearing',
    'For Directions',
    'For Orders',
    'For Issues',
    'For Evidence',
    'For Hearing',
    'EX-PARTE Hearing',
    'For Pronouncement of Order',
    'dkm',
    'FOR PRONOUNCEMENT',
    'ORDER RESERVED',
    'TRANSFER TO MEDIATION',
    'SINE DIE',
    'TO BE MENTIONED (FOR WITHDRAWAL)',
    'FOR LISTING AS PER LISTING NO DATE',
    'FOR DIRECTIONS (LOCKDOWN MATTERS)',
  ]
  const spacing = [1,1,2,3,2,3];

  const handleSelectAll = (event) => {
    event.stopPropagation();
    if (event.target.checked) {
      setSelectedCases(cases.map(item => `${item.id}`));
    } else {
      setSelectedCases([]);
    }
  }

  const tableHeadings = [
    <Checkbox
      color="primary"
      style={{padding:'5px'}}
      checked={cases.length === selectedCases.length}
      onChange={handleSelectAll}
    />,
    'Sr. No.',
    'Case No.',
    'Party Detail',
    'Date',
    'Head',
  ];
  
  const handleChangeOnFileCheckbox = (event) => {
    event.stopPropagation();
    if (event.target.checked) {
      selectedCases.push(event.target.id);
      setSelectedCases([...selectedCases]);
    } else {
      setSelectedCases(() => {
        return selectedCases.filter((x) => {
          return x !== event.target.id;
        });
      });
    }
  };

  const processResponse = (data,index) => {
    const item = data;
    const isSelected = selectedCases?.includes(`${item.id}`);
    const arr = [
      <Checkbox
        id={item.id}
        color="primary"
        style={{ padding: "5px", height: "fit-content" }}
        checked={isSelected}
        onChange={handleChangeOnFileCheckbox}
      />,
      index+1, 
      item?.main_case?.case_number,  
      item?.main_case?.name,
      validateDateAndFormat(item?.listing_date_timestamp,'dd/MM/yy'), 
      <FormControl size="small" required fullWidth margin="none" variant="outlined">
        <Select 
          name="head"
          value={item?.head}
          onChange={handleChangeHead(item?.id)}
          placeholder="Select"
          error={item?.headError}
        >
          <MenuItem >Select</MenuItem>
          {headList.map((head,index)=>{
            return(
              <MenuItem value={head} key={index}>{head}</MenuItem>
            )
          })}
        </Select>
        {item?.headError && (
          <FormHelperText style={{color:'red', margin:0}}>{item?.headError}</FormHelperText>
        )}
      </FormControl> 
    ];
    return arr;
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // console.log(e);
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }; 

  const handleInputChangeCourtNumberTransferTo = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      'courtNumberTransferTo': value,
    }));
  }

  const handleListingDateInput = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      'listing_date': value,
    }));
  }

  const handleAlocationDateInput = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      'alocation_date': value,
    }));
  }

  const handleSubmitSearch = (e) => {
    e.preventDefault();
    setLoading(true);
    setShowTable(true);
    setCases([]);
    // console.log(formData);
    const formatedDate = validateDateAndFormat(formData.listing_date, 'dd-MM-yyyy');
    getCasesToBeTransfer(formData.benchNature, formatedDate, formData.courtNumber).then((res)=>{
      // console.log(res.data);
      setCases(res?.data?.results);
      setLoading(false);
    }).catch((error)=>{
      console.log(error);
      setLoading(false);
      if(error?.response?.status === 400){
        showSnackbar(error?.response?.data?.message, 'error');
      }
    })
  }

  const handleSubmitCaseTransfer = (e) => {
    e.preventDefault();
    let headNotSelected = false;
    const selected_cases = selectedCases.map(item => {
      const caseIndex = cases.findIndex(element => element.id.toString() === item);
      const obj = cases[caseIndex];
      if(!obj?.head){
        cases[caseIndex] = {
          ...obj,
          'headError' : 'Please select head.'
        }
        headNotSelected = true;
      }
      return {
        "case_id": obj?.main_case?.id,
        "head_of_causelist": obj?.head,
      }
    })
    if(headNotSelected){
      setCases([...cases]);
      alert('Please select head of selected cases.');
      return;
    }
    const data = {
      "allocation_date": validateDateAndFormat(formData.alocation_date, 'dd-MM-yyyy'),
      "listing_date": validateDateAndFormat(formData.listing_date, 'dd-MM-yyyy'),
      "bench_nature": formData.benchNatureTransferTo,
      "new_court_number": formData.courtNumberTransferTo,
      "existing_court_number": cases[0].court_number,
      "cases": selected_cases,
    }

    caseTransfer(data).then((res)=>{
      // console.log(res.data);
      if(res?.data?.message){
        showSnackbar(res?.data?.message, "success");
        setFormData({
          ...formData,
          alocation_date: '',
          benchNatureTransferTo: '',
          courtNumberTransferTo: '',
        })
        setSelectedCases([]);
      }
    }).catch((error)=>{
      console.log(error);
      if(error?.response?.status === 400){
        showSnackbar(error?.response?.data?.message, 'error');
      }
    })
  }

  const handleChangeHead = (id) => (event) => {
    setCases(cases.map(item => item.id === id ? { ...item, head: event.target.value, headError: null } : item));
  };

  const handleGoToCreateBench = () => {
    handleClickOnNavItem(16);
  }

  return (
    <Box className={classes.root}>
      <Typography align="center" style={{fontSize:'1.5rem', marginBottom: '48px'}}>
        BENCH TO BENCH CASE TRANSFER
      </Typography>
      <form onSubmit={handleSubmitSearch}>
        <Grid container spacing={2} style={{backgroundColor:'white', padding:'2rem 1rem', alignItems:'start'}}>
          <Grid item xs={4}>
            <FormControl fullWidth margin="none" variant="outlined">
              <Typography className={classes.label}>{'Date*'}</Typography>
              <DatePickerTDSAT
                date={formData.listing_date}
                setDate={handleListingDateInput}
                isRequired={true}
                size="small"
                maximumDate={new Date()}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl size="small" required fullWidth margin="none" variant="outlined">
              <Typography className={classes.label}>{'Bench Nature*'}</Typography>
              <Select
                name="benchNature"
                value={formData.benchNature}
                onChange={handleInputChange}
              >
                <MenuItem value='single_bench'>Single Bench</MenuItem>
                <MenuItem value='division_bench'>Division Bench</MenuItem>
                <MenuItem value='full_bench'>Full Bench</MenuItem>
                <MenuItem value= 'registrar'>Registrar</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <CourtNumberInput
              courtNumber={formData.courtNumber}
              onChange={handleInputChange}
              listingDate={formData.listing_date}
              isRequired={true}
              size='small'
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              type="submit"
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
      <Box style={{height:'1.5rem'}}></Box>
      {showTable && ( 
        <Box style={{backgroundColor:'white'}}>
        <Box style={{height:'1rem'}}></Box>
        <Box>
          <Grid container style={{ rowGap: "8px" }}>
            <Box style={{padding:'1.5rem 8px', width: '100%'}}>
              <Grid container spacing={2} justifyContent="flex-start">
                {tableHeadings.map((heading,index)=>{
                  return (
                    <Grid key={index} item xs sm={spacing[index]}>
                      <Typography className={classes.tableHeadItem}>
                        {heading}
                      </Typography>
                    </Grid>
                  )
                })}
              </Grid>
            </Box>
            {loading ? (
              <Box style={{margin:'auto',width:'100%',padding:'2rem 0'}}>
                <CircularCenteredLoader/>
              </Box>
            ) : (
              cases.length !== 0 ? (
                cases.map((dispute,index) => {
                  const processedDispute = processResponse(dispute,index);
                  return (
                    <Box key={index} className={classes.valuePaper}>
                      <Grid container spacing={2} justifyContent="flex-start">
                        {processedDispute.map((item,index)=>{
                          return (
                            <Grid key={index} item xs sm={spacing[index]}>
                              <Typography className={classes.tableRowValue}>
                                {item}
                              </Typography>
                            </Grid>
                          )
                        })}
                      </Grid>
                    </Box>
                  );
                })
              ) : (
                <Grid container justify="center">
                  <Grid item>
                    <Box textAlign="center">
                      <Typography
                        style={{
                          padding: '2rem 8px',
                          textAlign: 'center',
                          fontSize: '1.5rem'
                        }}
                      >
                        No case found to be transfered.
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              )
            )}
          </Grid>
        </Box>
      </Box>
      )}
      <Box style={{height:'1.5rem'}}></Box>
      {selectedCases.length > 0 && (
        <>
          <form onSubmit={handleSubmitCaseTransfer}>
            <Grid container spacing={2} style={{backgroundColor:'white', padding:'2rem 1rem', alignItems:'start'}}>
              <Grid item xs={12}>
                <Typography className={classes.formHeading}>
                  {selectedCases.length} Case(s) Selected
                </Typography>
                <Typography style={{fontSize:'0.9rem', textAlign:'left'}}>
                  You can't transfer the Case if the Bench is not already created. <Link color="primary" underline="always" style={{cursor:'pointer'}} onClick={handleGoToCreateBench}>Click here to Create the Bench.</Link>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth margin="none" variant="outlined">
                  <Typography className={classes.label}>{'Allocation Date*'}</Typography>
                  <DatePickerTDSAT
                    date={formData.alocation_date}
                    setDate={handleAlocationDateInput}
                    isRequired={true}
                    size="small"
                    minimumDate={new Date()}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl size="small" required fullWidth margin="none" variant="outlined">
                  <Typography className={classes.label}>{'Bench Nature*'}</Typography>
                  <Select
                    name="benchNatureTransferTo"
                    value={formData.benchNatureTransferTo}
                    onChange={handleInputChange}
                  >
                    <MenuItem value='single_bench'>Single Bench</MenuItem>
                    <MenuItem value='division_bench'>Division Bench</MenuItem>
                    <MenuItem value='full_bench'>Full Bench</MenuItem>
                    <MenuItem value= 'registrar'>Registrar</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <CourtNumberInput
                  courtNumber={formData.courtNumberTransferTo}
                  onChange={handleInputChangeCourtNumberTransferTo}
                  listingDate={formData.alocation_date}
                  isRequired={true}
                  size='small'
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </>
      )}
    </Box>
  )
}