import React, { useState } from 'react';
import { Paper, Grid, TextField, Button, MenuItem, Select,FormHelperText, InputLabel, FormControl, makeStyles, Typography } from '@material-ui/core';
import DatePickerTDSAT from 'features/Tdsat/components/DatePickerTDSAT';

const GenerateTemplateForm = ({classes, formData, searchResponse, handleInputChange , handleFormSubmit, handleDateInput }) => {

  const [errors, setErrors] = useState({
    orderDate: '',
    benchNature: '',
    courtNumber: '', 
  });

const handleGenerateTemplate = async () => {
    let hasError = false;
    let newErrors = { orderDate: '', benchNature: '', courtNumber: ''};

    if (!formData.orderDate) {
        newErrors.orderDate = 'Order Date is required';
        hasError = true;
    }
    if (!formData.benchNature) {
      newErrors.benchNature = 'Bench Nature is required';
      hasError = true;
    }
    if (!formData.courtNumber) {
      newErrors.courtNumber = 'Court Number is required';
      hasError = true;
    } 

    setErrors(newErrors);

    if (!hasError) {
        handleFormSubmit(formData);
    }
  };

  return (
    <Paper elevation={3} className={classes.paper}>
      <Grid container spacing={2} item direction='column'>
        <Typography className={classes.titleHeader}>{searchResponse?.main_case?.name || " "}</Typography>

        <div className={classes.advocateInfo}>
          <p className={classes.advocateInfoTitle}>Petitioner's Advocate: {searchResponse?.main_case?.petitioner_advocate || " "}</p>
          <p className={classes.advocateInfoTitle}>Respondent's Advocate: {searchResponse?.main_case?.respondant_advocate || " "}</p>
        </div>

            <FormControl fullWidth margin="none" variant="outlined"  className={classes.formItem} >
              <DatePickerTDSAT
                date={formData.orderDate}
                setDate={handleDateInput}
                label={"Order Date"}
              />
               {errors.orderDate && <FormHelperText>{errors.orderDate}</FormHelperText>}
            </FormControl>

          {/* Row: Bench Nature and Court Number */}
          <div className={classes.rowItem}>
            {/* Bench Nature Dropdown */}
            <FormControl className={classes.formItem} style={{ marginRight: '10px' }} required variant="outlined">
              <InputLabel id="bench-nature-label">Bench Nature</InputLabel>
              <Select
                labelId="bench-nature-label"
                value={formData?.benchNature}
                onChange={handleInputChange}
                name="benchNature"
              >
                <MenuItem value="single_bench">Single Bench</MenuItem>
                <MenuItem value="division_bench">Division Bench</MenuItem>
                <MenuItem value="full_bench">Full Bench</MenuItem>
                <MenuItem value="registrar">Registrar</MenuItem>
              </Select>
              {errors.benchNature && <FormHelperText error>{errors.benchNature}</FormHelperText>}
            </FormControl>

            {/* Court Number Input */}
            <TextField
              label="Court Number"
              name="courtNumber"
              className={classes.formItem}
              value={formData?.courtNumber}
              onChange={handleInputChange}
              style={{ marginLeft: '10px' }}
              variant="outlined"
              error={!!errors.courtNumber}
              helperText={errors.courtNumber}
            />
          </div>

          {/* Generate Template Button */}
          <div className={classes.button}>
            <Button variant="contained" disabled={!searchResponse} color="primary" type="submit" className={classes.button} onClick={handleGenerateTemplate}>
              Generate Template
            </Button>
          </div>
      </Grid>
    </Paper>
  );
};

export default GenerateTemplateForm;
