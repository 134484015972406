  import {
    Box,
    Breadcrumbs,
    Button,
    CircularProgress,
    Collapse,
    Container,
    Grid,
    IconButton,
    makeStyles,
    Paper,
    Typography,
    TextField,
    Divider
  } from "@material-ui/core";
  import React, { useEffect, useState } from "react";
  import { useHistory } from "react-router-dom";
  import { DocGenerator } from "utils";
  import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import instance from "api/globalaxios";
import { ExpandLess, ExpandMore, KeyboardBackspace, NavigateNext, PlayForWork } from "@material-ui/icons";
import { format } from "date-fns";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import { Link } from "react-router-dom";
import CustomDialogTdsat from "./CustomDialogTdsat";
import AdminDefectTable from "./components/AdminDefectTable";
import TimelineComponent from "./components/Timelines";
import CourtFeeDetailsForm from "./components/CourtFeeDetailsForm";
import { getStatus } from "./TdsatContainer";
import { SUB_ADMIN, TDSAT_CASE_MANAGER, TDSAT_SECRETARIAT, TDSAT_SUB_ADMIN_TIAC_RV } from "app/constants";
import CaseRegisterSuccessScreen from "./CaseRegisterSuccessScreen copy";


  const useStyles = makeStyles((theme) => ({
		root: {
			marginTop: "2rem",
			[theme.breakpoints.down("sm")]: {
				marginTop: "1.5rem",
			},
			[theme.breakpoints.down("xs")]: {
				marginTop: "1rem",
			},
		},
    text: {
      fontFamily: `"Inter", "sans-serif"`,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "24px",
    },
    blue: {
      fontFamily: `"Inter", "sans-serif"`,
      color: "#2F80ED",
      lineHeight: "24px",
    },
    fonts: {
      lineHeight: "24px",
      fontSize: "16px",
      fontFamily: `"Inter", "sans-serif"`,
    },
    item: {
      "&:hover": {
        background: "#EEEEEE",
      },
    },
    calenderBtn: {
      fontSize: "16px",
      fontWeight: "400",
      color: "#2F80ED",
      textTransform: "none",
      borderRadius: "2px",
      border: "1px solid #2F80ED",
      boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.24)",
      width: "100%",
      maxWidth: "100%",
      "&:hover": {
        background: "#2F80ED",
        color: "#FFFFFF",
        border: "1px solid #2F80ED",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
      },
			hov: {
				"&:hover": {
					color: "#333333",
				},
			},
			btntext: {
				color: theme.palette.primary.main,
				fontFamily: "Satoshi-Bold",
				textTransform: "none",
			},
    },
		claimantDetailsContainer: {
			display: "flex",
			flexDirection: "column",
			width: "100%",
			padding: "2rem",
			borderRadius: '4px',
			backgroundColor: 'white',
		},
		claimantDetailsHeading: {
			marginBottom: "1rem",
			fontSize:'1.5rem',
			lineHeight:'1.8rem',
			color:'black',
      textAlign: 'left',
		},
		claimantDetailsStatus: {
			borderRadius:'4px',
			padding:'8px',
			width:'fit-content',
			lineHeight:1,
		},
		claimantDetailsRow: {
			padding: '10px 0',
			borderBottom: '1px solid #d7dbe2',
			// display: 'flex',
			// gap: '10px',
			[theme.breakpoints.between("sm",'md')]: {
				display: 'block',
      },
		},
		disputeStatusContainer: {
			display: "flex",
			flexDirection: "column",
			width: "100%",
			padding: "0.8rem 1rem 0.8rem 3rem",
		},
		disputeStatusHeading: {
			marginBottom: "0.5rem",
			fontSize:'1.3rem',
			lineHeight:'1.8rem',
			color:'black'
		},
		button: {
			color: 'black',
			"&:disabled": {
				backgroundColor: '#0000001f',
				color: '#00000042',
			}
		},
    boldText: {
      fontFamily: 'Satoshi-Bold',
      textAlign: 'left',
    },
    defectsContainer:{
      maxHeight: "300px",
      overflowY: "scroll",
      padding : "20px 30px",
      '&::-webkit-scrollbar': {
        width: '7px',
        background: '#fff',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#858D98',
        borderRadius : "20px"
      },
    },
    defectheading:{
      color: '#242529',
      fontSize: '18px',
      fontWeight: 700,
      marginTop: '20px'
    },
    defectitemheading:{
      color: '#242529',
      fontSize: '16px',
      fontWeight: 700,
      lineHeight : "25px"
    },
    defectitemdescription:{
      color: '#7C7C7C',
      fontSize: '12px',
      fontWeight: 500,
      lineHeight : "18px"
    },
  }));

	export async function getCaseDetails(id, type) {
		return await instance({
			method: "get",
			url: type === 'main_cases' ? `/tdsat/main-cases/?id=${id}` : type === 'caveats' ? `/tdsat/caveat/${id}/` : type === 'court_fees' ? `/tdsat/courtfees/${id}/` : `/tdsat/${type}/${id}/`,
		});
	}

  export async function updateCaseDetails(id, type, data) {
		return await instance({
			method: "patch",
			url: type === 'main_cases' ? `/tdsat/main-cases/?id=${id}` : type === 'caveats' ? `/tdsat/caveat/${id}/` : `/tdsat/${type}/${id}/`,
      data: data,
		});
	}

	export async function getCaseFiles(id) {
		return await instance({
			method: "get",
			url: "api/casefiles/" + id + "/",
		});
	}

  export async function getUploadedFileDetails(id, type) {
		return await instance({
			method: "get",
			url: `tdsat/files/fetch-files/?content_type=${type}&object_id=${id}`
		});
	}

  export async function getUploadedFileUrl(id, type, file_id) {
		return await instance({
			method: "get",
			url: `tdsat/files/fetch-file-url/?content_type=${type}&object_id=${id}&pk=${file_id}`
		});
	}

  export async function getPartyDetails(ids) {
    let url = 'tdsat/api/party/retrieve/';
    if(ids?.length > 0){
      ids.map((id,index)=>{
        url = url + `${index === 0 ? '?' : '&'}ids=${id}`
      })
    }
		return await instance({
			method: "get",
			url: url
		});
	}

  export async function createDefectTdsat(id, type, heading, description) {
		return await instance({
			method: "post",
			url: `tdsat/defects/`,
      data: {
        id: id,
        type: type,
        heading: heading,
        description: description
      },
		});
	}

  export async function getCaseTimeline(id, type) {
    return await instance({
      method: "get",
      url: `tdsat/timeline/?id=${id}&type=${type}`,
    });
  }

  export async function getCourtFeeReceipts(id, type) {
    return await instance({
      method: "get",
      url: `tdsat/court-fee-receipts/list-receipts/?object_id=${id}&content_type=${type}`,
    });
  }


  export default function CaseDetailsTDSAT({
    profile,
    clickedRow,
    handleGoBack,
  }) {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const {id, type} = clickedRow;
    const {profile_type} = profile;

    const [activeCase, setActiveCase] = useState({});
    const [timeline, setTimeLine] = useState([]);
    const [feeReceipts, setFeeReceipts] = useState([]);

    const [loading, setLoading] = useState(false);

    console.log(profile,clickedRow);
    React.useEffect(() => {
      setLoading(true)
      getCaseDetails(id, type).then((res) => {
        console.log(res,res.data);
        setActiveCase(res.data);
        setLoading(false);
      });
      getCaseTimeline(id, type)
      .then((res)=>{
        setTimeLine(res.data);
      })
      getCourtFeeReceipts(id, getType(type)).then((res)=>{
        if(res?.status === 200){
          setFeeReceipts(res?.data);
        }
      })
			// getClaimantAnswerData(activeDispute?.id).then((res) => {
			// 	const tempObject = res.data.reduce(
			// 		(obj, item) => Object.assign(obj, { [item.question_text]: {question_id:item.question_id, question: item.question_text, answer:item.answer_value} }), {}
			// 	);
			// 	setClaimantAnswers(tempObject);
			// })
			// getCaseFiles(activeDispute?.id).then((res)=>{
			// 	const caseFiles = res.data;
			// 	const tempObj = {
			// 		created: activeDispute.created,
			// 		gro: caseFiles.find(file => file.type === 'respondent')?.review_complete_date || undefined,
			// 		io: caseFiles.find(file => file.type === 'io')?.review_complete_date || undefined,
			// 		md: caseFiles.find(file => file.type === 'md')?.review_complete_date || undefined,
			// 	}
			// 	setTimeLines(tempObj);
			// })
    }, []);

    const [open, setOpen] = useState(false);
    const [heading, setHeading] = useState('');
    const [description, setDescription] = useState('');

    const handleOpen = () => {
        setOpen(true);
      };

    const handleClose = () => {
      setHeading('')
      setDescription('')
      setOpen(false);
    };

    const handleSend = () => {
      // Handle send action
      createDefectTdsat(id, type, heading, description).then((res) => {
       alert('Defect Registered Successfully')
      });
      handleClose();
    };

    const handleRegisterCase = () => {
      const data = {
        is_approved: true,
        ...(type !== 'main_cases') && {
          case_number: activeCase?.case_number,
          main_case: activeCase?.main_case,
          year: activeCase?.year,
        }
      }
      updateCaseDetails(activeCase?.id, type, data).then((res)=>{
        console.log(res.data);
        setActiveCase(res.data);
      })
    }

    function PetitionerData(){
      const [partiesDetails, setPartiesDetails] = useState([]);
      const partyIds = activeCase.claimant_parties.map((party)=> party?.id || party);
      const [loading, setLoading] = useState(false);

      useEffect(()=>{
        if(partyIds?.length > 0){
          setLoading(true);
          getPartyDetails(partyIds).then((res)=>{
            setPartiesDetails(res.data);
            setLoading(false);
          })
        }
      },[])

      if(loading){
        return <CircularProgress size={20}/>
      }

      return (
        (partiesDetails?.length > 0) ? (
          partiesDetails.map((party,index)=>{
            return (
              <Box key={index} style={{paddingTop:'15px'}}>
                <Grid container spacing={1}>
                  <Grid item >
                    <Typography>
                      Petitioner(s)/Appellant(s):
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{fontFamily:'Satoshi-Bold'}}>
                      {party.type}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item >
                    <Typography>
                      Name:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{fontFamily:'Satoshi-Bold'}}>
                      {party.full_name}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item >
                    <Typography>
                      Father’s Name/Husband’s Name:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{fontFamily:'Satoshi-Bold'}}>
                      {party.father_or_husband_name}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item >
                    <Typography>
                      Mobile Number:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{fontFamily:'Satoshi-Bold'}}>
                      {party.phone_number}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item >
                    <Typography>
                      Alternate Phone Number:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{fontFamily:'Satoshi-Bold'}}>
                      {party.alt_phone_number}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item >
                    <Typography>
                      Correspondence Address:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{fontFamily:'Satoshi-Bold'}}>
                      {party.correspondence_address}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item >
                    <Typography>
                      E-mail Address:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{fontFamily:'Satoshi-Bold'}}>
                      {party.email}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            )
          })
        ):(
          <Typography>Party not found!</Typography>
        )
      )
    }

    function RespondentData(){
      const [partiesDetails, setPartiesDetails] = useState([]);
      const partyIds = activeCase.respondent_parties.map((party)=> party?.id || party);
      const [loading, setLoading] = useState(false);

      useEffect(()=>{
        if(partyIds?.length > 0){
          setLoading(true);
          getPartyDetails(partyIds).then((res)=>{
            setPartiesDetails(res.data);
            setLoading(false);
          })
        }
      },[])

      if(loading){
        return <CircularProgress size={20}/>
      }

      return (
        (partiesDetails?.length > 0) ? (
          partiesDetails.map((party,index)=>{
            return (
              <Box key={index} style={{paddingTop:'15px'}}>
                <Grid container spacing={1}>
                  <Grid item >
                    <Typography>
                      Respondent(s):
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{fontFamily:'Satoshi-Bold'}}>
                      {party.type}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item >
                    <Typography>
                      Name:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{fontFamily:'Satoshi-Bold'}}>
                      {party.full_name}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item >
                    <Typography>
                      Father’s Name/Husband’s Name:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{fontFamily:'Satoshi-Bold'}}>
                      {party.father_or_husband_name}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item >
                    <Typography>
                      Mobile Number:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{fontFamily:'Satoshi-Bold'}}>
                      {party.phone_number}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item >
                    <Typography>
                      Alternate Phone Number:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{fontFamily:'Satoshi-Bold'}}>
                      {party.alt_phone_number}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item >
                    <Typography>
                      Correspondence Address:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{fontFamily:'Satoshi-Bold'}}>
                      {party.correspondence_address}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item >
                    <Typography>
                      E-mail Address:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={{fontFamily:'Satoshi-Bold'}}>
                      {party.email}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            )
          })
        ):(
          <Typography>Party not found!</Typography>
        )
      )
    }

    function CaseTypeData(){
      return (
        <Box style={{paddingTop:'15px'}}>
          <Grid container spacing={1}>
            <Grid item >
              <Typography>
                Case Type:
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{fontFamily:'Satoshi-Bold'}}>
                {activeCase?.document_type || activeCase?.case_type_new}
              </Typography>
            </Grid>
          </Grid>
          {(type === 'main_cases') && (
            <>
              <Grid container spacing={1}>
                <Grid item >
                  <Typography>
                    Is Government a Party:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography style={{fontFamily:'Satoshi-Bold'}}>
                    {activeCase?.is_government_party ? 'Yes' : 'No'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item >
                  <Typography>
                    Is this case related to a previously filed case:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography style={{fontFamily:'Satoshi-Bold'}}>
                    {activeCase?.is_related_to_previous_case ? 'Yes' : 'No'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item >
                  <Typography>
                    Case Year:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography style={{fontFamily:'Satoshi-Bold'}}>
                    {activeCase?.case_year_connected || '--'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item >
                  <Typography>
                    Case No.:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography style={{fontFamily:'Satoshi-Bold'}}>
                    {activeCase?.case_no_connected || '--'}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
          
        </Box>
      )
    }

    function ImpugnedOrderData(){
      return (
        <Box style={{paddingTop:'15px'}}>
          <Grid container spacing={1}>
            <Grid item >
              <Typography>
                Impugned Order/Direction/Notice:
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{fontFamily:'Satoshi-Bold'}}>
                {activeCase?.impunged_order_direction_notice_connected || '--'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item >
              <Typography>
                Court Name OR Name of Organisation:
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{fontFamily:'Satoshi-Bold'}}>
                {activeCase?.court_org_name_connected || '--'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item >
              <Typography>
                Is this case related to a previously filed case:
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{fontFamily:'Satoshi-Bold'}}>
                {activeCase?.is_related_to_previous_case ? 'Yes' : 'No'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item >
              <Typography>
                Decision Date:
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{fontFamily:'Satoshi-Bold'}}>
                {activeCase?.decision_date_connected || '--'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item >
              <Typography>
                Decision:
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{fontFamily:'Satoshi-Bold'}}>
                {activeCase?.decision_connected || '--'}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )
    }

    function PaymentData(){
      return (
        <Box>
          {feeReceipts.length > 0 && (
            feeReceipts.map((item,index)=>{
              return (
                <Box key={index} style={{paddingTop:'15px'}}>
                  <Grid container spacing={1}>
                    <Grid item >
                      <Typography>
                        Receipt Number {index+1}:
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography style={{fontFamily:'Satoshi-Bold'}}>
                        {item?.receipt_number || '--'}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item >
                      <Typography>
                        Date of Payment:
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography style={{fontFamily:'Satoshi-Bold'}}>
                        {item?.created_at ? format(new Date(item?.created_at), "dd/MM/yyyy"):''}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              )
            })
          )}
        </Box>
      )
    }

    function getType(type) {
      switch(type) {
        case 'main_cases':
          return 'MainCase';
        case 'applications':
          return 'Application';
        case 'documents':
          return 'Document';
        case 'caveats':
          return 'Caveat';
        default:
          return 'Unknown Type';
      }
    }

    const handleDownloadFile = (file_id,file_name) => (
      getUploadedFileUrl(id, getType(type), file_id).then((res) => {
        const url = res?.data?.url;
         const link = document.createElement("a");
         link.href = url;
         link.setAttribute("download", file_name);
         document.body.appendChild(link);
         link.click();
      })
    );

    function FilesData(){
      const [uploadedFileDetails, setUploadedFileDetails] = useState([]);
      const [uploadedFileUrl, setUploadedFileUrl] = useState('');
      const [loading, setLoading] = useState(false);

      useEffect(()=>{
        handleFileDetails();
      },[])

      function handleFileDetails (){
        setLoading(true);
        getUploadedFileDetails(id, getType(type)).then((res) => {
          setUploadedFileDetails(res?.data);
          setLoading(false);
        });
        
      }

      if(loading){
        return <CircularProgress size={20}/>
      }
  
      return (
        <>
          {uploadedFileDetails?.length > 0 ? (
            <Box style={{display:'flex',flexDirection:'column',gap:'1rem'}}>
              {uploadedFileDetails.map((file,index)=>{
                return (
                  <Box key={index} style={{display:'flex',gap:'10px', alignItems:'center'}}>
                    <Typography>{file?.file_name} </Typography>
                    <IconButton
                      onClick={()=> handleDownloadFile(file?.id,file?.file_name)}
                    >
                      <PlayForWork color="primary"/>

                      {/* <a href={uploadedFileUrl}>Download</a> */}
                    </IconButton >
                  </Box>
                )
              })}
            </Box>
          ):(
            <Typography>Files not found</Typography>
          )}
        </>
      )
    }



    function ExpandableRow({key,title,content}){
      const [open, setOpen] = useState(false);
      const handleClickView = () => {
        setOpen(!open);
      }
      return(
        <Box key={key} className={classes.claimantDetailsRow}>
          <Grid
            container
            justify="space-between"
            spacing={1}
            onClick={handleClickView}
            style={open?{backgroundColor:'#EBF2FF',cursor:'pointer'}:{cursor:'pointer'}}
            alignItems="center"i
          >
            <Grid item >
              <Typography className={classes.boldText}>
                {title}
              </Typography>
            </Grid>
            <Grid item  style={{textAlign:'right'}}>
              <Typography onClick={handleClickView}>
                {open ? <ExpandLess /> : <ExpandMore />}
              </Typography>
            </Grid>
          </Grid>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box style={{width:'fit-content',padding:'10px 0'}}>
              {content}
            </Box>
          </Collapse>
        </Box>
      )
    }

    const caseDetails = [
      {
        title: 'Petitioner(s)/Appellant(s)',
        content: <PetitionerData/>,
      },
      {
        title: 'Respondent(s)',
        content: <RespondentData/>,
      },
      {
        title: 'Case Type',
        content: <CaseTypeData/>,
      },
      ...(type === 'main_cases' ? [{
        title: 'Impugned Order/Direction/Notice',
        content: <ImpugnedOrderData/>,
      }]:[]),
      {
        title: 'Payment Id',
        content: <PaymentData/>,
      },
      {
        title: 'Uploaded File',
        content: <FilesData/>,
      },
    ]

		function Details() {
      return (
        <Paper className={classes.claimantDetailsContainer}>
					<Typography className={classes.claimantDetailsHeading}>
						{activeCase?.diary_number} | {type === 'main_cases' ? activeCase?.case_type_new : activeCase?.document_type}
					</Typography>
					<Typography className={classes.claimantDetailsStatus} style={activeCase.is_approved ? {backgroundColor: '#caf4d1'} : {backgroundColor:'#ffefeb'}}>
						{activeCase.is_approved ? 'Registered' : 'Filing under review'}
					</Typography>
          {caseDetails.map((item,index)=>{
            return (
              <ExpandableRow
                key={index}
                title={item.title}
                content={item.content}
              />
            )
          })}
				</Paper>
      )
    }

    if (loading) return <CircularCenteredLoader />;

    return (
      <>
        <Grid container justify="center" style={{height:'100%'}}>
          <Grid item lg={11} md={11} sm={11} xs={11} className={classes.root}>
            <Box style={{display:'flex',paddingBottom:'1.5rem',gap:'10px'}}>
              <Typography style={{cursor:'pointer'}} onClick={()=>{handleGoBack(11)}}>
                <KeyboardBackspace/>
              </Typography>
              <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
                <Link color="inherit" onClick={()=>{handleGoBack(11)}}>
                  Scrutiny
                </Link>
                <Typography color="textPrimary">{activeCase?.diary_number}</Typography>
              </Breadcrumbs>
            </Box>
            <Grid container style={{justifyContent:'space-between', gap:'2rem', alignItems:'center'}}>
              <Grid item xs>
                <Typography className="lineClamp" style={{fontFamily:'Satoshi-Bold',textAlign:'left'}}>{activeCase?.name}</Typography>
              </Grid>
              <Grid item style={{display:'flex',gap:'1rem'}}>
                <Box style={{display:'flex',alignItems:'center',gap:'1rem'}}>
                  <Typography>
                    {activeCase?.case_creation_date_time ? format(new Date(activeCase?.case_creation_date_time), "dd MMMM, y, h:mm a"):''}
                  </Typography>
                </Box>
                {/* <Button
                  variant="contained"
                  color="primary"
                  style={{padding:'5px 15px'}}
                  onClick={handleRegisterCase}
                  disabled={activeCase?.is_approved}
                >
                  Register
                </Button>
                <Button
                  className={classes.button}
                  variant="outlined"
                  onClick={handleOpen}
                  disabled={activeCase?.is_approved}
                >
                  Object
                </Button>
                <Button
                  className={classes.button}
                  variant="outlined"
                  disabled={activeCase?.is_approved}
                >
                  Deficit Court Fee
                </Button> */}
              </Grid>
            </Grid>
            <Box style={{height:'2rem'}}/>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={6} xs={12} style={{ display: "flex" }}>
                <Details/>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12} style={{ display: "flex" }}>
                <TimelineComponent timelineData={timeline} status={getStatus(activeCase?.current_step)} handleDownloadFile={handleDownloadFile}/>
              </Grid>
              <Grid item xs={12} style={{ display: "flex" }}>
                {activeCase?.is_payment_complete ? (
                  (((activeCase?.current_step === 'dealing_assistant' && profile_type === SUB_ADMIN) ||
                   (activeCase?.current_step === 'dealing_assistant_after_review' && profile_type === SUB_ADMIN) ||
                   (activeCase?.current_step === 'desk_officer' && profile_type === TDSAT_SECRETARIAT) ||
                   (activeCase?.current_step === 'deputy_registrar' && profile_type === TDSAT_CASE_MANAGER) ||
                   (activeCase?.current_step === 'registrar' && profile_type === TDSAT_SUB_ADMIN_TIAC_RV)) &&
                   (activeCase?.is_approved ? (
                    <CaseRegisterSuccessScreen activeCase={activeCase}/>
                   ):(
                     <AdminDefectTable activeCase={activeCase} setActiveCase={setActiveCase} handleRegisterCase={handleRegisterCase}/>
                   ))
                  )
                ):(
                  <CourtFeeDetailsForm activeCase={activeCase} setActiveCase={setActiveCase} feeReceipts={feeReceipts}/>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <CustomDialogTdsat
        open={open}
        onClose={handleClose}
        title="List Objections"
        onSend={handleSend}
        toShowBtn={true}
      >
        <TextField
            label="Heading"
            fullWidth
            margin="dense"
            variant="outlined"
            value={heading}
            onChange={(e) => setHeading(e.target.value)}
        />
        <TextField
            label="Description"
            fullWidth
            margin="dense"
            variant="outlined"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            rows={2}
        />
        <Typography className={classes.defectheading}>Defects</Typography>
        <Box className={classes.defectsContainer}>
          {activeCase?.defects?.map((defect, index) => (
            <>
              <Typography className={classes.defectitemheading}>
                Defect {index + 1}: {defect.heading}
              </Typography>
              <Typography className={classes.defectitemdescription}>
                {defect.description}
              </Typography>
              {index < activeCase?.defects?.length - 1 && (
                <Box style={{ margin: "10px 0" }}>
                  <Divider />
                </Box>
              )}
            </>
          ))}
        </Box>
        </CustomDialogTdsat>
      </>
    );
  }
