import React, {useState, useEffect} from "react";
import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    Grid,
    Paper,
    FormHelperText
} from '@material-ui/core';
import { getjudgesBench } from "../Bench/BenchForm";
import DatePickerTDSAT from "features/Tdsat/components/DatePickerTDSAT";
import CourtNumberInput from "features/Tdsat/components/CourtNumberInput";
import { CASE_TYPES_WITH_LABEL } from "../Notice/NoticeCaseForm";

const YEARS = ['Select', '2021', '2022', '2023', '2024'];
const CASE_TYPE = ["ADR", "AERA", "BA", "BP", "CA", "EA", "TA", "TP"]


const SearchByDateForm = ({classes, formData, handleInputChange, handleSearch, setFormData}) => {
    const [errors, setErrors] = useState({
        case_type_in_listing: '',
        benchNature: '',
        courtNumber: '',
        judgeName: ''
    });
    const [judges, setJudges] = useState([]);
    useEffect(() => {
        const fetchJudges = async () => {
          try {
            const response = await getjudgesBench();
            setJudges(response?.data);  
          } catch (error) {
            console.error("Error fetching judges", error);
          }
        };
    
        fetchJudges();
      }, []);

    const handleSearchClick = async () => {
        let hasError = false;
        let newErrors = {date: '', case_type_in_listing: '',  benchNature: '', courtNumber: '', judgeName: ''};

        if (!formData.date) {
            newErrors.date = 'Date is required';
            hasError = true;
        }
        if (!formData.case_type_in_listing) {
            newErrors.case_type_in_listing = 'Case Type is required';
            hasError = true;
        }
        if (!formData.benchNature) {
            newErrors.benchNature = 'Bench Nature is required';
            hasError = true;
        }
        if (!formData.courtNumber) {
            newErrors.courtNumber = 'Court Number is required';
            hasError = true;
        }
        if (!formData.judgeName) {
            newErrors.judgeName = 'Judge Selection is required';
            hasError = true;
        }

        setErrors(newErrors);

        if (!hasError) {
            handleSearch(formData);
        }
    };

    const handleDateInput = (value) => {
        console.log(value,'value')
        setFormData((prevData) => ({
          ...prevData,
          'date': value,
        }));
    }
    
    return(
        <Paper className={classes.paper}>
            <Grid container spacing={2}>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl fullWidth margin="none" variant="outlined">
                            <DatePickerTDSAT
                                date={formData?.date}
                                setDate={handleDateInput}
                                label="Date"
                            />
                        </FormControl>
                        {errors.date && <FormHelperText error>{errors.date}</FormHelperText>}
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth margin="none" variant="outlined">
                        <Typography className={classes.label}>Case Type</Typography>
                        <Select
                            label={'Case Type'}
                            name="case_type_in_listing"
                            value={formData.case_type_in_listing}
                            onChange={handleInputChange}
                        >
                            {CASE_TYPES_WITH_LABEL.map((option, index) => (
                                <MenuItem key={index} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                        {errors.case_type_in_listing && <FormHelperText error>{errors.case_type_in_listing}</FormHelperText>}
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl className={classes.formControl} fullWidth required margin="none" variant="outlined">
                        <Typography className={classes.label}>Bench Nature</Typography>
                        <Select
                            name="benchNature"
                            value={formData.benchNature}
                            onChange={handleInputChange}
                        >
                            <MenuItem value="single_bench">Single Bench</MenuItem>
                            <MenuItem value="division_bench">Division Bench</MenuItem>
                            <MenuItem value="full_bench">Full Bench</MenuItem>
                            <MenuItem value="registrar">Registrar</MenuItem>
                        </Select>
                        {errors.benchNature && <FormHelperText error>{errors.benchNature}</FormHelperText>}
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <CourtNumberInput
                            courtNumber={formData.courtNumber}
                            onChange={handleInputChange}
                            listingDate={formData.date}
                            isRequired={true}
                        />
                        {errors.courtNumber && <FormHelperText error>{errors.courtNumber}</FormHelperText>}
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth margin="none" variant="outlined">
                        <Typography className={classes.label}>Judge Name</Typography>
                        <Select
                            label={'Judge Name'}
                            name="judgeName"
                            value={formData.judgeName}
                            onChange={handleInputChange}
                        >
                            {judges?.map((judge) => (
                              <MenuItem key={judge.judge_code} value={judge.id}>
                                  {judge?.name}
                              </MenuItem>
                            ))}
                        </Select>
                        {errors.judgeName && <FormHelperText error>{errors.judgeName}</FormHelperText>}
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start', marginTop: 18 }}>
            <Button
                variant="contained"
                color="primary"
                fullWidth
                className={classes.button}
                onClick={()=>handleSearchClick(formData)}
            >
                Search
            </Button>
            </Grid>
        </Paper>
    )
}

export default SearchByDateForm;