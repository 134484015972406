import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography} from '@material-ui/core';
import Login from './Login';
import ForgotPassword from './ForgotPassword';
import OtpVerification from './OtpVerification';
import SetNewPassword from './SetNewPassword';
import SignUp from './Signup';
import SignupVerification from './SignupVerification';
import WebnyayLogo from "images/webnyay.svg";

const LoginTdsatFlow = () => {
  const [currentStep, setCurrentStep] = useState('login');
  const [otpVerificationValues, setOtpVerificationValues] = useState({})

  const handleSetOtpValues = (values) => {
    setOtpVerificationValues(values);
  };

  const renderStep = () => {
    switch (currentStep) {
      case 'login':
        return <Login setCurrentStep={setCurrentStep} />;
      case 'forgotPassword':
        return <ForgotPassword setCurrentStep={setCurrentStep} />;
      case 'otpVerification':
        return <OtpVerification setCurrentStep={setCurrentStep} />;
      case 'setNewPassword':
        return <SetNewPassword setCurrentStep={setCurrentStep} />;
      case 'signUp':
        return <SignUp setCurrentStep={setCurrentStep} setOtpVerificationValues={handleSetOtpValues}/>;
      case 'signUpVerification':
        return <SignupVerification setCurrentStep={setCurrentStep}  otpVerificationValues={otpVerificationValues}/>;
      default:
        return <Login setCurrentStep={setCurrentStep} />;
    }
  };

  return (
    <Box display="flex" height="100vh" flexDirection={{ xs: 'column', sm: 'row' }}>
      <Box width={{ xs: '100%', sm: '50%' }} bgcolor="#4A3BFF" height={{ xs: 'auto', sm: '100vh' }} p={3} display={'flex'} justifyContent={'space-between'} flexDirection={'column'}>
        <Grid>
          {/* <img src={tdsatIcon} alt="TDSAT Logo" style={{ width: 100, height: 100 }} /> */}
        </Grid>
        <Grid>
            <Typography style={{fontSize: '2.5rem', fontWeight: 'bold', color: '#fff'}}>Telecom Dispute Settlement & Appellate Tribunal (TDSAT)
            E-Filing Portal</Typography>
            <Typography style={{fontSize: '1.5rem', fontWeight: 'bold', marginTop: '30px',color: '#fff'}}>Seamless, secure, and efficient way to manage your cases.</Typography>
        </Grid>
        <Grid style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <Typography style={{fontSize: '1rem', color: '#fff' }}>Designed & Developed by:</Typography>
          <img src={WebnyayLogo} alt="Webnyay Logo" style={{ width: 100, height: 100 }} />
        </Grid>
      </Box>
      <Box width={{ xs: '100%', sm: '50%' }} height={{ xs: 'auto', sm: '100vh' }}  display="flex" justifyContent={currentStep === 'signUp' ? 'flex-start' : 'center'} alignItems="center" flexDirection="column" p={3}>
        {renderStep()}
      </Box>
    </Box>
  );
};

export default LoginTdsatFlow;
