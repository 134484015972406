import {
    Box,
    Button,
    Collapse,
    Hidden,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
    Paper,
    Typography,
  } from "@material-ui/core";
  import React, { useState, useEffect } from "react";
  import { FormattedMessage } from "react-intl";
  import { useHistory } from "react-router-dom";
  import { getWindowDimension } from "utils/responsiveUtils";
  import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
  import instance from "api/globalaxios";
  import TDSAT_logo from "../../images/TDSAT_Logo_Golden.svg";
import MainCaseInbox from "./MainCaseInbox";
import PreviouslyFiledCases from "./PreviouslyFiledCases";
import DraftInbox from "./DraftInbox";
import { AssignmentOutlined, AssignmentTurnedInOutlined, ContactMailOutlined, DescriptionOutlined, ExpandLess, ExpandMore, FeaturedPlayListOutlined, GavelOutlined, ListAltOutlined, ListOutlined, PaymentOutlined, PersonAddOutlined, PlaylistAdd, PlaylistAddCheckOutlined, PowerSettingsNew, PrintOutlined, PublishOutlined, SecurityOutlined, Settings, WorkOutline } from "@material-ui/icons";
import ApplicationFilingForm from "./ApplicationFillingForm";
import PersonalInformation from "./PersonalInformation";
import MainCase from "./MainCase";
import DocumentFilingForm from "./DocumentFilingForm";
import Caveat from "./Caveat";
import ProcessFees from "./ProcessFees";
import classNames from "classnames";
import Ashok_Chakra from "images/Ashoka_Chakra.svg";
import { logout, setLogOut } from "slices/loginSlice";
import { useDispatch } from "react-redux";
import { SUB_ADMIN, TDSAT_SECRETARIAT, TDSAT_CASE_MANAGER, TDSAT_SUB_ADMIN_TIAC_RV, SUB_ADMIN_RV } from "app/constants";
import CaseDetailsTDSAT, { getCaseDetails } from "./CaseDetailsTDSAT";
import CauseListReport from "./CauseListReport";
import BenchWiseHeadPriority from "./components/BenchWiseHeadPriority";
import CourtUpdateContainer from "./CourtUpdate/CourtUpdateContainer";
import AdminScrutinyReport from "./Admin/ScrutinyReportAdmin";
import ScrutinyReprint from "./Admin/ScrutinyReprint";
import NoticeCis from "./CIS/Notice";
import BenchCis from "./CIS/Bench";
import OrderCis from "./CIS/Order";
import UploadJudgement from "./CIS/UploadJudgement";
import CaseListing from "./CIS/CaseListing";
import AdditionalCase from "./CIS/AdditionalCase";
import ReportContainer from "./CIS/Report/ReportContainer";
  
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      width: "100%",
      // minHeight: "95vh",
      height: "100vh",
      borderRadius: "1rem",
      // flexDirection: (props) => (props.smallScreen ? "column" : "row"),
    },
    sidebar: {
      background: theme.palette.primary.main,
      width: "23rem",
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    sidebarItem: {
      //#A4A6B3;
      fontSize: "16px",
      padding: "1rem 2rem",
      color: "white",
      gap: '1rem',
    },
    selected: {
      backgroundColor: "white",
      color: theme.palette.primary.main,
      borderRadius: '0.5rem',
      padding: '1rem 0 1rem 2rem',
      '&:hover':{
        background: 'white'
      },
    },
    primaryText: {
      fontFamily: 'Satoshi-Bold',
    },
    body: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      padding: "0",
      overflow: "hidden",
      width: "100%",
      textAlign: "center",
      alignItems: 'center',
      overflowY: 'auto',
    },
    body2: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      padding: "0",
      overflow: "hidden",
      width: "100%",
      textAlign: "center",
    },
    header: {
      display: 'flex',
      padding: '0.5rem 1.5rem',
      gap: '20px',
      alignItems: 'center',
      justifyContent: 'center',
      borderBottom: `1px solid #858D98`,
      width:'100%',
      boxSizing: 'border-box',
    },
    headerHeading: {
      flex: 1,
      fontSize:'1.375rem', 
      lineHeight: 'normal'
    },
    list: { 
      flex:1, 
      overflowY:'auto',
      '&::-webkit-scrollbar':{
        width: '14px',
      },
      '&::-webkit-scrollbar-thumb':{
        border: '4px solid transparent',
        borderRadius: '10px',
        backgroundColor: '#a9a8a8',
        backgroundClip: 'content-box',
      }
    }
  }));

  export const getStatus = (step) => {
    switch(step){
      case 'submitted':
      case '':
        return 'Financial Scrutiny';
      case 'party_as_defective':
        return 'Court Fee-In defecit';
      case 'dealing_assistant':
      case 'dealing_assistant_after_review':
        return 'Dealing Assistant';
      case 'desk_officer':
        return 'Desk Officer';
      case 'deputy_registrar':
        return 'Deputy Registrar';
      case 'registrar':
        return 'Registrar';
      default:
        return '';
    }
  }
  
  export default function Tdsat({profile}) {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const IS_ADMIN = profile?.profile_type === SUB_ADMIN || profile?.profile_type === TDSAT_SUB_ADMIN_TIAC_RV || profile?.profile_type === TDSAT_SECRETARIAT || profile?.profile_type === TDSAT_CASE_MANAGER;
    const IS_CIS_ADMIN = profile?.profile_type === SUB_ADMIN_RV;

    const ls = sessionStorage.getItem("tiac_sidebar_step");
    const mb = sessionStorage.getItem("tiac_sidebar_mobilestep");
  
    const [step, setStep] = useState(mb ? mb : 0);
  
    const removeSessionStorage = () => {
      sessionStorage.clear();
    };
  
    const [selected, setSelected] = useState(
      ls ? parseInt(ls) : getWindowDimension().width < 960 ? 0 : IS_ADMIN ? 11 : IS_CIS_ADMIN ? 14 : 5
    );

    const [isDetailsScreen, setIsDetailsScreen] = useState(false);
    const [clickedRow, setClickedRow] = useState({
      id: '',
      type: '',
    })

    const [draftCase, setDraftCase] = useState(null);

    const handleClickOnRow = (id, type) => {
      setIsDetailsScreen(true);
      setClickedRow({
        id: id,
        type: type,
      })
    }

    const getTabId = (type) => {
      switch(type){
        case 'main_cases':
          return 5;
        case 'applications':
          return 6;
        case 'documents':
          return 7;
        case 'caveats':
          return 8;
        case 'court_fees':
          return 9;
        default:
          return 2;
      }
    }

    const handleResumeCaseFiling = (id, type) => {
      console.log(id, type);
      getCaseDetails(id, type).then((res)=>{
        setDraftCase(res?.data);
        handleClickOnNavItem(getTabId(type));
      })
      .catch((error)=>{
        console.log('There is some glitch in fetching details',error);
      })
    }
  
    const RenderOptions = () => {
      if (selected === 2) {
        return <MainCaseInbox profile={profile} handleClickOnRow={handleClickOnRow} />;
      }
      if (selected === 3) {
        return <PreviouslyFiledCases/>;
      }
      if (selected === 4) {
        return <DraftInbox handleResumeCaseFiling={handleResumeCaseFiling}/>;
      }
      if (selected === 5 || selected === 0 || selected === 1) {
        return <MainCase draftCase={draftCase} setSelected={setSelected}/>;
      }
      if (selected === 6) {
        return <ApplicationFilingForm draftCase={draftCase}/>;
      }
      if (selected === 7) {
        return <DocumentFilingForm draftCase={draftCase}/>;
      }
      if (selected === 8) {
        return <Caveat draftCase={draftCase}/>;
      }
      if (selected === 9) {
        return <ProcessFees draftCase={draftCase}/>;
      }
      if (selected === 10) {
        return <PersonalInformation profile={profile}/>
      }
      if (selected === 11) {
        return <MainCaseInbox profile={profile} handleClickOnRow={handleClickOnRow} />;
      }
      if (selected === 12) {
        return <AdminScrutinyReport/>
      }
      if (selected === 13) {
        return <ScrutinyReprint/>
      }
      if (selected === 14) {
        return <AdminScrutinyReport/>
      }
      if (selected === 15) {
        return <NoticeCis/>
      }
      if (selected === 16) {
        return <BenchCis/>;
      }
      if (selected === 17) {
        return <CauseListReport/>
      }
      if (selected === 18) {
        return <CaseListing/>;
      }
      if (selected === 19) {
        return <CourtUpdateContainer handleClickOnNavItem={handleClickOnNavItem}/>
      }
      if (selected === 20) {
        return <OrderCis/>;
      }
      if (selected === 21) {
        return <UploadJudgement/>
      }
      if (selected === 22) {
        return <ReportContainer/>
      }
      if (selected === 23) {
        return <AdditionalCase/>
      }
      if (selected === 24) {
        return <h1>Create User</h1>
      }
      if (selected === 25) {
        return <h1>Master</h1>
      }
      if (selected === 26) {
        return <h1>TDSAT Statement</h1>
      }
    };

    const handleClickOnNavItem = (number, draftCase, setDraftCase) => {
      setSelected(number);
      setStep(1);
      removeSessionStorage();
      sessionStorage.setItem("tiac_sidebar_mobilestep", 1);
      sessionStorage.setItem("tiac_sidebar_step", number);
      setIsDetailsScreen(false);
      if(draftCase && (number !== getTabId(draftCase?.type))){
        setDraftCase(null);
      }
    }

    const eFilingListItem = [
      {
        id: 5,
        title: 'Main Case',
        icon: <WorkOutline/>,
        dropDownList: null,
        clickHandler: handleClickOnNavItem,
      },
      {
        id: 6,
        title: 'Application',
        icon: <DescriptionOutlined/>,
        dropDownList: null,
        clickHandler: handleClickOnNavItem,
      },
      {
        id: 7,
        title: 'Document',
        icon: <AssignmentOutlined/>,
        dropDownList: null,
        clickHandler: handleClickOnNavItem,
      },
      {
        id: 8,
        title: 'Caveat',
        icon: <ListAltOutlined/>,
        dropDownList: null,
        clickHandler: handleClickOnNavItem,
      },
      {
        id: 9,
        title: 'Court Fee',
        icon: <PaymentOutlined/>,
        dropDownList: null,
        clickHandler: handleClickOnNavItem,
      },
    ]

    const navListItem = [
      // ...!IS_ADMIN ? [
      //   {
      //     id: 1,
      //     title: 'E-Filing',
      //     icon: null,
      //     dropDownList: eFilingListItem,
      //     clickHandler: null,
      //   }
      // ]:[],
      {
        id: 1,
        title: 'E-Filing',
        icon: null,
        dropDownList: eFilingListItem,
        clickHandler: null,
      },
      {
        id: 2,
        title: 'Filing done up to date',
        icon: null,
        dropDownList: null,
        clickHandler: handleClickOnNavItem,
      },
      {
        id: 3,
        title: 'Registered Cases',
        icon: null,
        dropDownList: null,
        clickHandler: handleClickOnNavItem,
      },
      {
        id: 4,
        title: 'Draft Inbox',
        icon: null,
        dropDownList: null,
        clickHandler: handleClickOnNavItem,
      },
    ];

    const adminNavListItem = [
      {
        id: 11,
        title: 'Scrutiny',
        icon: <DescriptionOutlined/>,
        dropDownList: null,
        clickHandler: handleClickOnNavItem,
      },
      {
        id: 12,
        title: 'Scrutiny Report',
        icon: <AssignmentTurnedInOutlined/>,
        dropDownList: null,
        clickHandler: handleClickOnNavItem,
      },
      {
        id: 13,
        title: 'Reprint scrutiny',
        icon: <PrintOutlined/>,
        dropDownList: null,
        clickHandler: handleClickOnNavItem,
      },
    ];

    const cisAdminNavListItem = [
      {
        id: 14,
        title: 'Scrutiny Report',
        icon: <DescriptionOutlined/>,
        dropDownList: null,
        clickHandler: handleClickOnNavItem,
      },
      {
        id: 15,
        title: 'Notice',
        icon: <AssignmentTurnedInOutlined/>,
        dropDownList: null,
        clickHandler: handleClickOnNavItem,
      },
      {
        id: 16,
        title: 'View Bench',
        icon: <FeaturedPlayListOutlined/>,
        dropDownList: null,
        clickHandler: handleClickOnNavItem,
      },
      {
        id: 17,
        title: 'Cause List',
        icon: <AssignmentOutlined/>,
        dropDownList: null,
        clickHandler: handleClickOnNavItem,
      },
      {
        id: 18,
        title: 'Case List',
        icon: <PlaylistAddCheckOutlined/>,
        dropDownList: null,
        clickHandler: handleClickOnNavItem,
      },
      {
        id: 19,
        title: 'Court Update',
        icon: <GavelOutlined/>,
        dropDownList: null,
        clickHandler: handleClickOnNavItem,
      },
      {
        id: 20,
        title: 'Daily Order',
        icon: <ContactMailOutlined/>,
        dropDownList: null,
        clickHandler: handleClickOnNavItem,
      },
      {
        id: 21,
        title: 'Upload Judgment',
        icon: <PublishOutlined/>,
        dropDownList: null,
        clickHandler: handleClickOnNavItem,
      },
      {
        id: 22,
        title: 'Report',
        icon: <DescriptionOutlined/>,
        dropDownList: null,
        clickHandler: handleClickOnNavItem,
      },
      {
        id: 23,
        title: 'Case Additional Details',
        icon: <PlaylistAdd/>,
        dropDownList: null,
        clickHandler: handleClickOnNavItem,
      },
      {
        id: 24,
        title: 'Create User',
        icon: <PersonAddOutlined/>,
        dropDownList: null,
        clickHandler: handleClickOnNavItem,
      },
      {
        id: 25,
        title: 'Master',
        icon: <SecurityOutlined/>,
        dropDownList: null,
        clickHandler: handleClickOnNavItem,
      },
      {
        id: 26,
        title: 'TDSAT Statement',
        icon: <ListOutlined/>,
        dropDownList: null,
        clickHandler: handleClickOnNavItem,
      },
    ];

    const [open, setOpen] = useState(true);
    const handleClick = () => {
      setOpen(!open);
    };

    const handleClickLogout = () => {
      sessionStorage.clear();
      dispatch(logout());
      dispatch(setLogOut());
      history.push("/");
    }

    function displayListItem(listItem){
      const isSelected = selected === listItem.id;
      const classesList = classNames({
        [classes.sidebarItem]: true,
        [classes.selected]: isSelected,
      });

      return (
        <>
          <ListItem
            key={listItem.title}
            className={classesList}
            button
            onClick={listItem?.dropDownList ? handleClick : ()=>listItem?.clickHandler(listItem.id,draftCase,setDraftCase)}
          >
            {listItem?.icon && (
              <ListItemIcon style={{color:'inherit',minWidth:'auto'}}>{listItem.icon}</ListItemIcon>
            )}
            <ListItemText classes={{primary:classes.primaryText}} primary={listItem.title}/>
            {listItem?.dropDownList && (
              open ? <ExpandLess /> : <ExpandMore />
            )}
            {(isSelected) && (
              <div style={{height:'1.6rem', width:'0.54963rem', borderRadius:'0.24rem', backgroundColor: '#4A3BFF'}}></div>
            )}
          </ListItem>
          {listItem?.dropDownList && (
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {listItem.dropDownList.map((nestedListItem,index)=>{
                  const isSelected = selected === nestedListItem.id;
                  const classesList = classNames({
                    [classes.sidebarItem]: true,
                    [classes.selected]: isSelected,
                  });
                  return (
                    <ListItem
                      key={nestedListItem.title}
                      className={classesList}
                      sx={{ pl: 4 }}
                      button
                      onClick={() => nestedListItem?.clickHandler(nestedListItem.id,draftCase,setDraftCase)}
                    >
                      {nestedListItem?.icon && (
                        <ListItemIcon style={{color:'inherit',minWidth:'auto'}}>{nestedListItem.icon}</ListItemIcon>
                      )}
                      <ListItemText  primary={nestedListItem.title}/>
                      {(isSelected) && (
                        <div style={{height:'1.6rem', width:'0.54963rem', borderRadius:'0.24rem', backgroundColor: '#4A3BFF'}}></div>
                      )}
                    </ListItem>
                  )
                })}
              </List>
            </Collapse>
          )}
        </>
      )
    }
  
    const Sidebar = ({draftCase,setDraftCase}) => {
      return (
        <Box className={classes.sidebar}>
          <Box height="1rem" />
          <Box style={{padding:'0.5rem 1rem'}}>
            <img src='/Logo_Colour.png' alt="webnyay" width='148px'></img>
          </Box>
          <List className={classes.list}>
            {IS_ADMIN ? (
              adminNavListItem.map(displayListItem)
            ) : (
              IS_CIS_ADMIN ? (
                cisAdminNavListItem.map(displayListItem)
              ) : (
                navListItem.map(displayListItem)
              )
            )}
          </List>
          
          <ListItem
            className={classes.sidebarItem}
            button
            onClick={() => handleClickOnNavItem(10)}
          >
            <ListItemIcon style={{color:'inherit',minWidth:'auto'}}><Settings/></ListItemIcon>
            <ListItemText classes={{primary:classes.primaryText}} primary={`Welcome ${profile?.user?.first_name}!`}/>
          </ListItem>
          <ListItem
            className={classes.sidebarItem}
            button
            onClick={handleClickLogout}
          >
            <ListItemIcon style={{color:'inherit',minWidth:'auto'}}><PowerSettingsNew/></ListItemIcon>
            <ListItemText classes={{primary:classes.primaryText}} primary={'Logout'}/>
          </ListItem>
        </Box>
      );
    };
  
    const Body = () => {
      return (
        <Box className={classes.body2}>
          <Hidden mdUp>
            <Paper
              style={{
                background: "none",
                border: "none",
                boxShadow: "none",
                display: "flex",
                justifyContent: "flex-start",
                padding: "15px 20px",
              }}
            >
              <Button
                size="large"
                onClick={(e) => {
                  setStep(0);
                  setSelected(0);
                  removeSessionStorage();
                }}
                startIcon={<ArrowBackIosIcon />}
              >
                <FormattedMessage id="buttons.back" />
              </Button>
            </Paper>
          </Hidden>
          <Box className={classes.body}>
            <Box className={classes.header}>
              <Box>
                <img src={TDSAT_logo} alt="" style={{width: '6rem'}}/>
              </Box>
              <Typography className={classes.headerHeading}>
                दूरसंचार विवाद समाधान एवं अपील अधिकरण <br/>
                Telecom Disputes Settlement & Appellate Tribunal
              </Typography>
              <Box>
                <img src={Ashok_Chakra} alt="" style={{width: '9rem'}}/>
              </Box>
            </Box>
            <Box style={{padding:'0.8rem'}}>
              <Typography color="primary" style={{fontSize:'1.2rem', fontFamily: 'Satoshi-bold'}}>E-FILING SYSTEM</Typography>
            </Box>
						{/* <h1>This is TDSAT Dashboard</h1> */}
            {isDetailsScreen ? (
              <CaseDetailsTDSAT profile={profile} clickedRow={clickedRow} handleGoBack={handleClickOnNavItem}/>
            ) : (
              <RenderOptions />
            )}
            
          </Box>
        </Box>
      );
    };
  
    const MobileView = ({ step }) => {
      switch (step) {
        case 0:
        case "0":
          return <Sidebar draftCase={draftCase} setDraftCase={setDraftCase} />;
        case 1:
        case "1":
          return <Body />;
        default:
          return null;
      }
    };
  
    return (
      <Box className={classes.root}>
        <Hidden smDown>
          <>
            <Sidebar draftCase={draftCase} setDraftCase={setDraftCase}/>
            <Body />
          </>
        </Hidden>
        <Hidden mdUp>
          <MobileView step={step} />
        </Hidden>
      </Box>
    );
  }
  