import React, { useEffect, useState } from 'react';
import {
  FormControl,
  MenuItem,
  Select,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { getHeadNameChoices } from '../API/TdsatApi';
import { format } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  label: {
    color: '#121212',
    fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: '1.3125rem',
    textAlign: 'left',
    marginBottom: '0.2rem',
  },
  
}));

const HeadOfCauseListInput = ({headOfListing, onChange, isRequired=false, isLabel = true}) => {
  const classes = useStyles();

  const [headNameChoices, setHeadNameChoices] = useState([]);

  useEffect(() => {
    const fetchCauseListHeaders = async () => {
      try {
        const response = await getHeadNameChoices();
        setHeadNameChoices(response?.data);  
      } catch (error) {
        console.error("Error fetching causeList", error);
      }
    };

    fetchCauseListHeaders();
  }, []);
  
  return (
    <>
      <FormControl required={isRequired} fullWidth margin="none" variant="outlined">
        {isLabel && <Typography className={classes.label}>Head Of Listing</Typography>}
        <Select
          name="headOfListing"
          value={headOfListing}
          onChange={onChange}
        >
          <MenuItem>Select</MenuItem>
          {headNameChoices?.map((item, index)=>{
            return (
              <MenuItem key={index} value={item?.id}>{item?.label}</MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </>
  );
};

export default HeadOfCauseListInput;
