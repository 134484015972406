import React, {useState} from 'react';
import { Typography, Divider, Box, makeStyles } from '@material-ui/core';
import SearchCaseForm from '../shared/SearchCaseForm';
import OrderUploadForm from '../shared/OrderUploadForm';
import GenerateTemplateForm from '../shared/GenerateTemplateForm';
import SuccessResult from '../shared/SuccessResult';
import { getSearchFormDetailsMainCase, generateTemplate, getSearchFormDetailsMainCaseByDiaryNumber } from 'features/Tdsat/API/TdsatApi';
import fileDownload from 'js-file-download';
import instance from 'api/globalaxios';
import { format } from 'date-fns';
import BackBtnBreadcrumb from '../shared/BackBtnBreadcrumb';
import { useSnackbar } from 'contexts/SnackbarContext';

const useStyles = makeStyles((theme)=>({
  form: {
    maxWidth: 600,
    margin: '0 auto',
    padding: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(10),
    marginTop: theme.spacing(2.5),
  },
  paper: {
    padding: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: 600,
    marginBottom: theme.spacing(4),
    borderRadius: '10px',
    marginTop:  theme.spacing(4),
  },
  // button: {
  //   padding: theme.spacing(1),
  //   borderRadius: 12,
  //   fontSize: 22,
  // },
  titleHeader: {
    fontSize: '22px',
    color: '#4A3BFF',
    fontWeight: 700
  },
  formItem: {
    marginBottom: '1rem',
    width: '100%',
  },
  rowItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  advocateInfo: {
    fontWeight: 500,
    marginBottom: '1.5rem',
  },
  advocateInfoTitle: {
    fontSize: '18px',
    fontWeight: 400,
    fontFamily: 'Satoshi-medium'
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '12px',
    padding: theme.spacing(1),
  },
  formItem: {
    marginBottom: '1rem',
    width: '100%',
  },
  uploadButton: {
    marginBottom: '1rem',
    display: 'flex',
    justifyContent: 'center',
  },
}));

const OrderForm = ({onBack}) => {
  const classes= useStyles();
  const [formData, setFormData] = useState({
    selectType: 'caseNumber',
    orderDate: null,
    orderUploadDate: null
  });
  
  const [searchResponse, setSearchResponse] = useState();
  const { showSnackbar } = useSnackbar();
  
  const handleSearch = async (searchParams) => {
    console.log(searchParams,'searchParams')
    try {
      const caseNumber = searchParams?.case_number;
      const diaryNumber = searchParams?.diary_number;
      const year = searchParams.year;
      const caseType = searchParams.case_type;
      let response;

      if (caseNumber) {
        response = await getSearchFormDetailsMainCase(caseNumber, year, caseType, "order");
      } else if (diaryNumber) {
        response = await getSearchFormDetailsMainCaseByDiaryNumber(diaryNumber, year, caseType, "order");
      } else {
        throw new Error("Either caseNumber or diaryNumber must be provided.");
      }
      if(response?.data){
        showSnackbar('Searched Successfully', 'success');
      }
      setSearchResponse(response?.data)
    } catch (error) {
      console.error("Error searching case:", error);
      if(error?.response?.status === 400){
        showSnackbar(error?.response?.data?.error, 'error');
      }
    }
  };

  const handleGenerateTemplate = async (formDetails) => {
    console.log(formDetails,'formDetails')
    try {
      const orderDate = format(formDetails?.orderDate, 'dd-MM-yyyy');;
      const benchNature = formDetails.benchNature;
      const courtNumber = formDetails.courtNumber;
      const response = await generateTemplate(searchResponse?.main_case?.id ,orderDate,benchNature, courtNumber, "daily_order");
      fileDownload(
        response.data,
        `GenerateTemplate".docx`
      );
      if(response?.data){
        showSnackbar('Template Generated Successfully', 'success');
      }
    } catch (error) {
      console.error("Error searching case:", error);
      if(error?.response?.status === 400){
        showSnackbar(error?.response?.data?.error, 'error');
      }
    }
  };

  const handleUploadOrder = (orderDetails) => {
    console.log('Uploaded Order:', orderDetails);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }; 

  const handleDateInput = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      'orderDate': value,
    }));
  }

  const handleDateInputUpload  = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      'orderUploadDate': value,
    }));
  }

  return (
    <Box>
      <BackBtnBreadcrumb
        onBackClick={() => onBack()}
        breadcrumbs={[
          { label: 'Daily Order', onClick: () => onBack() },
        ]}
        currentPath='Create Order'
      />
      <Typography variant="h4" align="center" >
        ORDER CREATION
      </Typography>

      {/* Search Case Section */}
      <SearchCaseForm classes={classes} formData={formData} handleInputChange={handleInputChange} handleSearch={handleSearch} />

      {searchResponse && <SuccessResult message={searchResponse?.message}/>}

      {/* Generate Template Section */}
      <GenerateTemplateForm classes={classes} handleDateInput={handleDateInput} handleInputChange={handleInputChange} searchResponse={searchResponse} formData={formData} handleFormSubmit={handleGenerateTemplate} />

      {/* Upload Order Section */}
      <OrderUploadForm classes={classes} handleDateInputUpload={handleDateInputUpload} handleInputChange={handleInputChange} searchResponse={searchResponse} formData={formData} handleFormSubmit={handleUploadOrder} apiUrl="/tdsat/order/"/>

      {/* {searchResponse && <SuccessResult message={"Document uploaded successfully."}/>} */}
    </Box>
  );
};

export default OrderForm;
