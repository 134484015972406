import { 
    Box, 
    Typography, 
    makeStyles, 
    Select, 
    Grid, 
    Button, 
    Paper, 
    TextField, 
    FormControl, 
    InputLabel, 
    MenuItem ,
    Divider,
    FormHelperText
} from "@material-ui/core";
import instance from "api/globalaxios";
import React, {useState} from "react";
import Swal from "sweetalert2";
import SuccessResult from "../CIS/shared/SuccessResult";
import fileDownload from "js-file-download";

const YEARS = ['Select', '2021', '2022', '2023', '2024'];

const useStyles = makeStyles((theme) => ({
    form: {
      maxWidth: 600,
      margin: '0 auto',
      padding: theme.spacing(3),
    },
    title: {
      marginBottom: theme.spacing(10),
      marginTop: theme.spacing(2.5),
    },
    paper: {
      padding: theme.spacing(4),
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      width: 600,
      marginBottom: theme.spacing(4),
      borderRadius: '10px'
    },
    button: {
        padding: theme.spacing(1),
        borderRadius: 12,
        fontSize: 22,
    },
    buttonPay: {
        padding: theme.spacing(1),
        borderRadius: 12,
        width: '50%',
        display: 'flex',
    },
    labelTitle: {
        display: 'flex',
        marginBottom: '8px',
        fontSize: '12px'
    },
    labelSubtitle: {
        display: 'flex',
        marginTop: '8px',
        fontSize: '11px',
        color: '#858D98',
        marginBottom: '32px'
    },
      amount: {
        fontSize: '18px',
      },
      titleCase: {
        color: '#000',
        fontSize: '16px',
        lineHeight: '22px',
        fontWeight: 700,
        display: 'flex'
      },
      nameTitle: {
        fontSize: '18px',
        lineHeight: '31.32px',
        fontWeight: 400,
        display: 'flex'
      },
      containerCaseInfo: {
        marginBottom: '18px'
      },
      userInfo: {
        display: 'flex',
        flexDirection: 'column'
      },
      containerUser: {
        marginTop: '18px'
      },
      titleUserInfo: {
        display: 'flex',
        color: '#000',
        fontSize: '16px'
      },
      partyName: {
        fontSize: '18px',
        fontWeight: '400'
      },
      submitButton: {
        display: 'flex',
        justifyContent: 'center'
      },
      heading: {
        marginBottom: '20px',
        fontWeight: 'bold',
      },
      infoBox: {
        textAlign: 'left',
        marginBottom: '10px',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
      },
      label: {
        fontWeight: 'bold',
      },
      value: {
        marginBottom: '10px',
      },
      button: {
        marginTop: '20px',
        backgroundColor: '#4a58e9',
        color: '#fff',
        '&:hover': {
          backgroundColor: '#3949ab',
        },
      },
}));

const ScrutinyReprint = () => {
    const [scrutinyData, setScrutinyData] = useState({
        diary_number: '',
        year: ''
    });
    const [errors, setErrors] = useState({
        year: '',
        diary_number: ''
    });
    const [scrutinyDetails, setScrutinyDetails] = useState({});
    const classes = useStyles();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setScrutinyData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
    };

    const handleGoClick = () => {
        let hasError = false;
        let newErrors = { year: '', diary_number: '' };

        if (!scrutinyData.diary_number) {
            newErrors.diary_number = 'Diary Number is required';
            hasError = true;
        }

        if (!scrutinyData.year || scrutinyData.year === 'Select') {
            newErrors.year = 'Year is required';
            hasError = true;
        }

        setErrors(newErrors);

        if (!hasError) {
            handleScrutinyReprint(scrutinyData);
        }
    }

    const handleScrutinyReprint = async (scrutinyData) => {
        try {
          const response = await instance.get(`/tdsat/reprint-scrutiny/caseretrieve?diary_number=${scrutinyData?.diary_number}`);
          setScrutinyDetails(response);
        } catch (error) {
            setScrutinyDetails({error: 'Scrutiny Not Completed'});
          console.error("There was an error fetching the case data!", error);
        }
    };

    const handlePrintSlipScrutiny = async (scrutinyDetails) => {
        try {
            const response = await instance.get(`tdsat/reprint-scrutiny/generate?type=${scrutinyDetails?.data?.type}&id=${scrutinyDetails?.data?.id}`);
            fileDownload(
              response.data,
              `ScrutinyReprint.pdf`
            );
            Swal.fire({
                title: "Print Slip",
                text: "Downloaded Slip Successfully",
                icon: "success"
              });
        }catch (error){
            console.error("There was an error fetching the case data!", error);
        }
    }

    return(
        <Box component="form" className={classes.form}>
            <Typography variant="h4" align="center" className={classes.title}>
                Scrutiny Reprint
            </Typography>
            <Paper className={classes.paper}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                label={'Diary Number'}
                                name="diary_number"
                                fullWidth
                                required
                                margin="none"
                                variant="outlined"
                                value={scrutinyData.diary_number}
                                onChange={handleInputChange}
                                error={!!errors.diary_number}
                                helperText={errors.diary_number}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth margin="none" variant="outlined">
                            <InputLabel>{'Year'}</InputLabel>
                            <Select
                                label={'Year'}
                                name="year"
                                value={scrutinyData.year}
                                onChange={handleInputChange}
                            >
                                {YEARS.map((option, index) => (
                                    <MenuItem key={index} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                            {errors.year && <FormHelperText>{errors.year}</FormHelperText>}
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start', marginTop: 18 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        className={classes.button}
                        onClick={()=>handleGoClick(scrutinyData)}
                    >
                        Search
                    </Button>
                </Grid>
            </Paper>
            {scrutinyDetails?.error && 
              <SuccessResult message={"Scrutiny Not Completed"}/>
            }
            {scrutinyDetails?.data && 
                <Paper className={classes.paper} elevation={3}>
                    <Box className={classes.infoBox}>
                        <Typography className={classes.label}>Parties' Name:</Typography>
                        <Typography className={classes.value}>{scrutinyDetails?.data?.name}</Typography>
                    </Box>

                    <Divider />
                    <Box className={classes.infoBox} mt={2}>
                        <Typography className={classes.label}>Case Type:</Typography>
                        <Typography className={classes.value}>{scrutinyDetails?.data?.case_type_new}</Typography> 
                    </Box>

                    <Button className={classes.button} color="primary" variant="contained" fullWidth onClick={()=>handlePrintSlipScrutiny(scrutinyDetails)}>
                        Print Slip
                    </Button>
                </Paper>
            }
        </Box>
    )
}

export default ScrutinyReprint;