import React, { useEffect, useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Grid, Button, TextField, MenuItem, makeStyles, Paper, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { createNewApplicant, getDetailsOfPresentApplicant, getDetailsOfPresentRespondent, updateDetailsApplicant, getAdvocates, createNewAdvocate, getAllPresentAdvocateInMainCase, deletePresentAdvocate } from 'features/Tdsat/API/TdsatApi';
import { useSnackbar } from 'contexts/SnackbarContext'; 
import CircularCenteredLoader from 'features/common/CircularCenteredLoader';

const ExpandableForm = ({ title, formFields, onSubmit, classes, formData, handleChange, handleSelectApplicant }) => {

  const handleSubmit = () => {
    onSubmit(formData);
  };

  return (
    <Accordion style={{width: '100%'}}>
      <AccordionSummary expandIcon={<ExpandMore />} className={classes.accordionTitle}>
        {title}
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          {/* Ensure form takes full width by wrapping it in a full-width grid item */}
          <Grid item xs={12}>
        <form >
        <Grid container spacing={2}>
          {/* Render dynamic form fields */}
          {formFields.map((field) => (
            <Grid item xs={field.halfWidth ? 6 : 12} key={field.name}>
              <Typography className={classes.label}>{field.label}</Typography>
              <TextField
                key={field.name}
                // label={field.label}
                name={field.name}
                className={classes.formItem}
                value={formData[field.name]}
                onChange={(e) => {
                  handleChange(e); // General handleChange
                  if (field.name === 'selectApplicant') {
                    handleSelectApplicant(e.target.value); // Trigger API call when applicant is selected
                  }
                }}
                required={field.required}
                select={field.type === 'select'}
                fullWidth
                variant='outlined'
              >
                {(field.name === 'selectApplicant' || field.name === 'partyName') && field.options.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.full_name}
                  </MenuItem>
                ))}
                {(field.name === 'type' || field.name === 'partyType') && field.options.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
                {field.name === 'advocateName' && field.options.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          ))}
          </Grid>
          <Button
            variant="contained"
            color="primary"
            className={classes.saveButton}
            onClick={handleSubmit}
            endIcon={'+'}
          >
            Save/Add
          </Button>
        </form>
        </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

const AddingDetailsTabs = ({classes, searchResponse}) => {
  const [formData, setFormData] = useState({
    type: '',
    fullName: '',
    fatherOrHusbandName: '',
    phoneNumber: '',
    alternatePhoneNumber: '',
    correspondenceAddress: '',
    email: '',
    selectApplicant: '',
  });

  const [formDataRespondent, setFormDataRespondent] = useState({
    type: '',
    fullName: '',
    fatherOrHusbandName: '',
    phoneNumber: '',
    alternatePhoneNumber: '',
    correspondenceAddress: '',
    email: '',
    selectApplicant: '',
  });

  const [formDataAdditionalParty, setFormDataAdditionalParty] = useState({
    type: '',
    fullName: '',
    fatherOrHusbandName: '',
    phoneNumber: '',
    alternatePhoneNumber: '',
    correspondenceAddress: '',
    email: '',
    advocateName: '',
    partyType: '',
    valueForPartyType: ''
  });

  const [formDataAdvocate, setFormDataAdvocate] = useState({
    type: '',
    partyName: '',
    advocateName: '',
  });

  const [advocates, setAdvocates] = useState([]);
  const [presentAdvocate, setPresentAdvocate] = useState([]);
  const [loading, setLoading] = useState(false);

  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchAdvocates = async () => {
      try {
        const response = await getAdvocates();
        setAdvocates(response?.data);  
      } catch (error) {
        console.error("Error fetching connectedCases", error);
      }
    };
    fetchAdvocates();
  },[])

  const fetchPresentAdvocateInMainCase = async (id, type) => {
    setLoading(true);
    try {
      const response = await getAllPresentAdvocateInMainCase(id, type);
      setLoading(false);
      setPresentAdvocate(response?.data);  
    } catch (error) {
      setLoading(false);
      console.error("Error fetching connectedCases", error);
    }
  };

  useEffect(() => {
    if(searchResponse?.main_case?.id && formDataAdvocate?.type){
      fetchPresentAdvocateInMainCase(searchResponse?.main_case?.id,formDataAdvocate?.type);
    }
  },[searchResponse?.main_case?.id, formDataAdvocate?.type])

  // Define the form fields for each section
  const applicantFields = [
    { name: 'selectApplicant', label: 'Select Applicant', type: 'select', required: true, options: searchResponse?.applicant_parties },
    { name: 'type', label: 'Petitioner/Appellant', type: 'select', required: true, options: ['petitioner','appellant'] },
    { name: 'fullName', label: 'Full Name', required: true },
    { name: 'fatherOrHusbandName', label: 'Father’s/Husband’s Name', required: true },
    { name: 'phoneNumber', label: 'Phone Number', required: true },
    { name: 'alternatePhoneNumber', label: 'Alternate Phone Number' },
    { name: 'correspondenceAddress', label: 'Correspondence Address', required: true },
    { name: 'email', label: 'Email', required: true },
  ];

  const respondentFields = [
    { name: 'selectApplicant', label: 'Select Respondent', type: 'select', required: true, options: searchResponse?.respondent_parties },
    { name: 'type', label: 'Respondent', type: 'select', required: true, options: ['respondent'] },
    { name: 'fullName', label: 'Full Name', required: true },
    { name: 'fatherOrHusbandName', label: 'Father’s/Husband’s Name', required: true },
    { name: 'phoneNumber', label: 'Phone Number', required: true },
    { name: 'alternatePhoneNumber', label: 'Alternate Phone Number' },
    { name: 'correspondenceAddress', label: 'Correspondence Address', required: true },
    { name: 'email', label: 'Email', required: true },
  ];

  const additionalPartyFields = [
    { name: 'type', label: 'Type', type: 'select', required: true, options: ['applicant','respondent'] },
    { name: 'partyType', label: 'Party Type', type: 'select', required: true, options: ['Licensor','Group of Consumers', 'Licensee','Regulator'], halfWidth: true },
    { name: 'valueForPartyType', label: 'Licensor/Group Of Consumers/Licensee/Regulator Name', required: true, halfWidth: true  },
    { name: 'advocateName', label: 'Advocate Name', type: 'select', required: true, options: advocates },
    { name: 'fullName', label: 'Full Name', required: true },
    { name: 'fatherOrHusbandName', label: 'Father’s/Husband’s Name', required: true },
    { name: 'phoneNumber', label: 'Phone Number', required: true },
    { name: 'alternatePhoneNumber', label: 'Alternate Phone Number' },
    { name: 'correspondenceAddress', label: 'Correspondence Address', required: true },
    { name: 'email', label: 'Email', required: true },
  ];

  const additionalAdvocateFields = [
    { name: 'type', label: 'Type', type: 'select', required: true, options: ['applicant','respondent'] },
    { name: 'partyName', label: 'Party Name', type: 'select', required: true, options: formDataAdvocate?.type === 'applicant' ? searchResponse?.applicant_parties : searchResponse?.respondent_parties , halfWidth: true },
    { name: 'advocateName', label: 'Advocate Name', type: 'select', required: true, options: advocates, halfWidth: true },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChangeRespondent = (e) => {
    const { name, value } = e.target;
    setFormDataRespondent((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChangeAdditionalParty = (e) => {
    const { name, value } = e.target;
    setFormDataAdditionalParty((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChangeAdvocate = (e) => {
    const { name, value } = e.target;
    setFormDataAdvocate((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSelectApplicant = async (applicantId) => {
    try {
      const response = await getDetailsOfPresentApplicant(applicantId);
      const data = response.data;

      // Populate form fields with the fetched applicant details
      setFormData({
        selectApplicant: applicantId,
        type: data.type || '', 
        fullName: data.full_name || '',
        fatherOrHusbandName: data.father_or_husband_name || '',
        phoneNumber: data.phone_number || '',
        alternatePhoneNumber: data.alt_phone_number || '',
        correspondenceAddress: data.correspondence_address || '',
        email: data.email || '',
      });
      showSnackbar('Applicant details fetched successfully!', 'success');
    } catch (error) {
      showSnackbar('Error fetching applicant details', 'error');
    }
  };

  const handleSelectRespondent = async (applicantId) => {
    try {
      const response = await getDetailsOfPresentRespondent(applicantId);
      const data = response.data;

      // Populate form fields with the fetched applicant details
      setFormDataRespondent({
        selectApplicant: applicantId,
        type: data.type || '', 
        fullName: data.full_name || '',
        fatherOrHusbandName: data.father_or_husband_name || '',
        phoneNumber: data.phone_number || '',
        alternatePhoneNumber: data.alt_phone_number || '',
        correspondenceAddress: data.correspondence_address || '',
        email: data.email || '',
      });
      showSnackbar('Applicant details fetched successfully!', 'success');
    } catch (error) {
      showSnackbar('Error fetching applicant details', 'error');
    }
  };

  const handleFormSubmit = async () => {
    const { selectApplicant, ...restFormData } = formData;

    const createNewData = {};
    if (formData.fullName) createNewData.full_name = formData.fullName;
    if (formData.type) createNewData.type = formData.type;
    if (formData.fatherOrHusbandName) createNewData.father_or_husband_name = formData.fatherOrHusbandName;
    if (formData.phoneNumber) createNewData.phone_number = formData.phoneNumber;
    if (formData.alternatePhoneNumber) createNewData.alt_phone_number = formData.alternatePhoneNumber;
    if (formData.correspondenceAddress) createNewData.correspondence_address = formData.correspondenceAddress;
    if (formData.email) createNewData.email = formData.email;

    // If no applicant is selected, create a new applicant
    if (!selectApplicant) {
      const newApplicantData = { 
          ...createNewData, 
          main_case_id: searchResponse?.main_case?.id 
      };

      try {
        await createNewApplicant(newApplicantData);
        setFormData({
          type: '',
          fullName: '',
          fatherOrHusbandName: '',
          phoneNumber: '',
          alternatePhoneNumber: '',
          correspondenceAddress: '',
          email: '',
          selectApplicant: '',
        });
        showSnackbar('New applicant created successfully!', 'success');
      } catch (error) {
        showSnackbar('Error creating new applicant', 'error');
      }
    } else {
      try {
        await updateDetailsApplicant(selectApplicant, createNewData);
        setFormData({
          type: '',
          fullName: '',
          fatherOrHusbandName: '',
          phoneNumber: '',
          alternatePhoneNumber: '',
          correspondenceAddress: '',
          email: '',
          selectApplicant: '',
        });
        showSnackbar('Applicant details updated successfully!', 'success');
      } catch (error) {
        showSnackbar('Error updating applicant details', 'error');      }
    }
  };

  const handleFormSubmitRespondent = async () => {
    const { selectApplicant, ...restFormData } = formDataRespondent;

    const createNewDataRespondent = {};
    if (formDataRespondent.fullName) createNewDataRespondent.full_name = formDataRespondent.fullName;
    if (formDataRespondent.type) createNewDataRespondent.type = formDataRespondent.type;
    if (formDataRespondent.fatherOrHusbandName) createNewDataRespondent.father_or_husband_name = formDataRespondent.fatherOrHusbandName;
    if (formDataRespondent.phoneNumber) createNewDataRespondent.phone_number = formDataRespondent.phoneNumber;
    if (formDataRespondent.alternatePhoneNumber) createNewDataRespondent.alt_phone_number = formDataRespondent.alternatePhoneNumber;
    if (formDataRespondent.correspondenceAddress) createNewDataRespondent.correspondence_address = formDataRespondent.correspondenceAddress;
    if (formDataRespondent.email) createNewDataRespondent.email = formDataRespondent.email;

    // If no applicant is selected, create a new applicant
    if (!selectApplicant) {
      const newRespondentData = { 
          ...createNewDataRespondent, 
          main_case_id: searchResponse?.main_case?.id 
      };

      try {
        await createNewApplicant(newRespondentData);
        setFormDataRespondent({
          type: '',
          fullName: '',
          fatherOrHusbandName: '',
          phoneNumber: '',
          alternatePhoneNumber: '',
          correspondenceAddress: '',
          email: '',
          selectApplicant: '',
        });
        showSnackbar('New respondent created successfully!', 'success');
      } catch (error) {
        showSnackbar('Error creating new applicant', 'error');
      }
    } else {
      try {
        await updateDetailsApplicant(selectApplicant, createNewDataRespondent);
        setFormDataRespondent({
          type: '',
          fullName: '',
          fatherOrHusbandName: '',
          phoneNumber: '',
          alternatePhoneNumber: '',
          correspondenceAddress: '',
          email: '',
          selectApplicant: '',
        });
        showSnackbar('Respondent details updated successfully!', 'success');
      } catch (error) {
        showSnackbar('Error updating respondent details', 'error');      }
    }
  };

  const handleFormSubmitAdditionalParty = async () => {
    const { selectApplicant, ...restFormData } = formDataAdditionalParty;

    const createNewDataAdditionalParty = {};
    if (formDataAdditionalParty.fullName) createNewDataAdditionalParty.full_name = formDataAdditionalParty.fullName;
    if (formDataAdditionalParty.type) createNewDataAdditionalParty.type = formDataAdditionalParty.type;
    if (formDataAdditionalParty.partyType) createNewDataAdditionalParty.party_type = formDataAdditionalParty.partyType;
    if (formDataAdditionalParty.valueForPartyType) createNewDataAdditionalParty.value_for_party_type = formDataAdditionalParty.valueForPartyType;
    if (formDataAdditionalParty.advocateName) createNewDataAdditionalParty.advocate_id = formDataAdditionalParty.advocateName;
    if (formDataAdditionalParty.fatherOrHusbandName) createNewDataAdditionalParty.father_or_husband_name = formDataAdditionalParty.fatherOrHusbandName;
    if (formDataAdditionalParty.phoneNumber) createNewDataAdditionalParty.phone_number = formDataAdditionalParty.phoneNumber;
    if (formDataAdditionalParty.alternatePhoneNumber) createNewDataAdditionalParty.alt_phone_number = formDataAdditionalParty.alternatePhoneNumber;
    if (formDataAdditionalParty.correspondenceAddress) createNewDataAdditionalParty.correspondence_address = formDataAdditionalParty.correspondenceAddress;
    if (formDataAdditionalParty.email) createNewDataAdditionalParty.email = formDataAdditionalParty.email;

    const newAdditionalPartyDetails = { 
        ...createNewDataAdditionalParty, 
        main_case_id: searchResponse?.main_case?.id 
    };

    try {
      await createNewApplicant(newAdditionalPartyDetails);
      setFormDataAdditionalParty({
        type: '',
        fullName: '',
        fatherOrHusbandName: '',
        phoneNumber: '',
        alternatePhoneNumber: '',
        correspondenceAddress: '',
        email: '',
        advocateName: '',
        partyType: '',
        valueForPartyType: ''
      });
      showSnackbar('New Additional Party created successfully!', 'success');
    } catch (error) {
      showSnackbar('Error creating new additional party', 'error');
    }
    
  };

  const handleFormSubmitAdvocate = async () => {
    const { selectApplicant, ...restFormData } = formDataAdvocate;

    const createNewDataAdvocate = {};
    if (formDataAdvocate.type) createNewDataAdvocate.type = formDataAdvocate.type;
    if (formDataAdvocate.partyName) createNewDataAdvocate.party_id = formDataAdvocate.partyName;
    if (formDataAdvocate.advocateName) createNewDataAdvocate.advocate_id = formDataAdvocate.advocateName;

    const newAdditionalAdvocate = { 
        ...createNewDataAdvocate, 
        main_case_id: searchResponse?.main_case?.id 
    };

    try {
      await createNewAdvocate(newAdditionalAdvocate);
      fetchPresentAdvocateInMainCase(searchResponse?.main_case?.id,formDataAdvocate?.type)
      setFormDataAdvocate({
        type: '',
        advocateName: '',
        partyName: '',
      });
      showSnackbar('Advocate added to main case successfully!', 'success');
    } catch (error) {
      showSnackbar('Error creating new advocate party', 'error');
    }
  };

  const handleDeleteAdvocate = async (rowId, advocateId) => {
    try {
      const response = await deletePresentAdvocate(rowId, advocateId);
      showSnackbar('Deleted successfully!', 'success');
    } catch (error) {
      showSnackbar('Error while deleting!', 'error');
    }
  }

  return (
    <Paper className={classes.paper}>
      <Grid container alignItems="center" >
          <Grid item>
            {searchResponse && <Typography className={classes.caseHeading}>[{searchResponse?.main_case?.id}] | [{searchResponse?.main_case?.case_number}]</Typography>}
          </Grid>
        </Grid>
      {searchResponse &&
      <>
        <ExpandableForm 
          title="Applicant Details" 
          formFields={applicantFields} 
          classes={classes} 
          formData={formData} 
          handleChange={handleChange} 
          handleSelectApplicant={handleSelectApplicant} 
          onSubmit={handleFormSubmit}
        />
         <ExpandableForm 
          title="Respondent Details" 
          formFields={respondentFields} 
          classes={classes} 
          formData={formDataRespondent} 
          handleChange={handleChangeRespondent} 
          handleSelectApplicant={handleSelectRespondent} 
          onSubmit={handleFormSubmitRespondent}
        />

        <ExpandableForm 
          title="Additional Party Details" 
          formFields={additionalPartyFields} 
          classes={classes} 
          formData={formDataAdditionalParty} 
          handleChange={handleChangeAdditionalParty} 
          onSubmit={handleFormSubmitAdditionalParty}
        />

        <ExpandableForm 
          title="Additional Advocate Details" 
          formFields={additionalAdvocateFields} 
          classes={classes} 
          formData={formDataAdvocate} 
          handleChange={handleChangeAdvocate} 
          onSubmit={handleFormSubmitAdvocate}
        />
        {loading ? (
          <div className={classes.loader}>
            <CircularCenteredLoader />
          </div>
        ) : presentAdvocate.length === 0 ? (
          <Typography variant="body1">
          </Typography>
        ) : (
          <>
            <TableContainer component={Paper}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow className={classes.tableRow}>
                    <TableCell className={classes.noBordersTitle}>Advocate</TableCell>
                    <TableCell className={classes.noBordersTitle}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {presentAdvocate?.length > 0 ? (
                    presentAdvocate.map((row, index) => (
                      <TableRow key={index} className={classes.tableRow}>
                        <TableCell className={classes.noBorders}>{row?.advocates?.[0]?.name}</TableCell>
                        <TableCell className={classes.noBorders}><DeleteOutlineIcon style={{cursor: 'pointer'}} onClick={()=>handleDeleteAdvocate(row?.id, row?.advocates?.[0]?.id)}/></TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4} className={classes.noBorders} align="center">
                        No Advocate
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </>
      }
    </Paper>
  );
};

export default AddingDetailsTabs;
