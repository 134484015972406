import React, { useEffect, useState } from 'react';
import {
  Table, TableBody, Tabs,Tab, TableCell, Grid, Box, TableContainer, TableHead, TableRow, TextField, Button, Paper, IconButton, TablePagination,makeStyles,
  Typography, FormControl
} from '@material-ui/core';
import { DeleteOutline, PlusOne, Print, Search } from '@material-ui/icons';
import instance from 'api/globalaxios';
import { format } from 'date-fns';
import CircularCenteredLoader from 'features/common/CircularCenteredLoader';
import Swal from 'sweetalert2';
import { formatDate } from 'utils';
import DateRangePickerTDSAT from 'features/Tdsat/DateRangePickerTDSAT';
import fileDownload from 'js-file-download';
import DatePickerTDSAT from 'features/Tdsat/components/DatePickerTDSAT';

const useStyles = makeStyles((theme) => ({
  root: {
		padding: '1.5rem',
    width: '100%',
    boxSizing: 'border-box',
	},
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  searchField: {
    marginRight: theme.spacing(1),
    flexGrow: 1,
    borderRadius: '8px',
  },
  table: {
    minWidth: 650,
    width: '100%',
    maxWidth: '100%'
  },
  noDefect: {
    color: 'green',
  },
  pagination: {
    marginTop: theme.spacing(2),
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  datePickers: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    flexGrow: 1
  },
  noBorders: {
    border: 'none',
    fontSize: '15px',
    
  },
  noBordersTitle: {
    border: 'none',
    fontSize: '15px',
    fontFamily: 'Satoshi-Bold',
  },
  buttonPrint: {
    borderRadius: '8px',
    border: '1px solid var(--BG-Color-1, #AAAFB9)',
    background: '#fff'
  },
  tableRow: {
    '& tr': {
      marginBottom: '8px !important'  
    },
    '& th, & td': { 
      padding: '26px !important',  
    },
    border: '1px solid #E5E5E5',
    background: '#fff',
  },
  tabContainer: {
    marginBottom: theme.spacing(2),
  },
  titleTop: {
    fontSize: '20px',
    fontWeight: 700,
  }
}));

const BenchTable = ({onFileNewNotice, onShowBenchPriority}) => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [countPagination, setCountPagination] = useState(0);
  const [listingDate, setListingDate] = useState(null);
  // const [endDate, setEndDate] = useState(null);
  const [loading, setLoading] = useState(false); 

  const tabTypes = ['notice', 'dasti_notice', 'ea/ma/other'];

  useEffect(() => {
    fetchScrutinyReportData();
  },[activeTab])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchScrutinyReportData(listingDate, newPage + 1); // Update data on page change
  };

  const handlePrintReport = async () => {
    if (!listingDate) {
      Swal.fire({
        title: "Error",
        text: "Please select date and then Submit.",
        icon: "error"
      });
      return;
    }
    try {
      const response = await instance.get(`tdsat/bench_report/generate?type=main_cases&date=${format(listingDate, 'dd-MM-yyyy')}`, {responseType: 'blob'});
      fileDownload(
        response.data,
        `BenchReport.pdf`
      );
      Swal.fire({
          title: "Print Slip",
          text: "Downloaded Report Successfully",
          icon: "success"
        });
    }catch (error){
        console.error("There was an error fetching the case data!", error);
    }
  };

  const handleDeleteBench = async (benchId) => {
    try {
      const response = await instance.delete(`tdsat/bench/${benchId}/`);
      Swal.fire({
          title: "Delete",
          text: "Deleted Bench Successfully",
          icon: "success"
        });
      fetchScrutinyReportData();
    }catch (error){
        console.error("There was an error fetching the case data!", error);
    }
  };

  const handleSubmit = () => {
    //  if (!listingDate) {
    //   Swal.fire({
    //     title: "Error",
    //     text: "Please select listing date and then submit",
    //     icon: "error"
    //   });
    //   return;
    // }
    fetchScrutinyReportData(listingDate); 
  };

  const fetchScrutinyReportData = async (listingDate, page = 1) => {
    setLoading(true); 
    try {
      const params = {
        date: listingDate ? format(listingDate, 'dd-MM-yyyy') : '',
        page: page > 1 ? page : 1,
      };
      const response = await instance.get('/tdsat/bench/', { params });
      setFilteredData(response?.data?.results || []);
      setCountPagination(response?.data?.count);
    } catch (error) {
      console.error('There was an error fetching the case data!', error);
    }
    setLoading(false);
  };

  const handleDateInput = (value) => {
    setListingDate(value)
  }

  return (
    <div className={classes.root}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Grid container alignItems="center" style={{justifyContent: 'space-between'}}>
          <Grid item>
            <Typography className={classes.titleTop}>View Bench</Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={onFileNewNotice}
              style={{ marginTop: '20px',  marginRight: '10px', borderRadius: '8px' }}
              endIcon={'+'}
            >
              Create Bench
            </Button>
            <Button
                variant="contained"
                color="primary"
                onClick={onShowBenchPriority}
                style={{ marginTop: '20px', marginRight: '10px', borderRadius: '8px' }}
                endIcon={'+'}
              >
                Purpose Priority
            </Button>
          </Grid>
        </Grid>
      </Box>
      <div className={classes.searchContainer}>
        <Grid item xs={12}>
          <FormControl fullWidth margin="none" variant="outlined">
            <DatePickerTDSAT
              date={listingDate}
              setDate={handleDateInput}
              label="Date"
            />
          </FormControl>
        </Grid>

        {/* Submit Button */}
        <Button onClick={handleSubmit} style={{ marginLeft: '10px' }} className={classes.buttonPrint}>
          Submit
        </Button>

        {/* Print Button */}
        <Button onClick={handlePrintReport} style={{ marginLeft: '10px' }} className={classes.buttonPrint}>
          <Print /> Print Report
        </Button>
      </div>
      
      {loading ? (
        <div className={classes.loader}>
          <CircularCenteredLoader />
        </div>
      ) : (
        <>
          <TableContainer component={Paper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableRow}>
                <TableCell className={classes.noBordersTitle}>Sr. No.</TableCell>
                <TableCell className={classes.noBordersTitle}>Bench Number</TableCell>
                <TableCell className={classes.noBordersTitle}>Court Number</TableCell>
                <TableCell className={classes.noBordersTitle}>Listing Date</TableCell>
                <TableCell className={classes.noBordersTitle}>Causelist Header</TableCell>
                <TableCell className={classes.noBordersTitle}>Justice</TableCell>
                <TableCell className={classes.noBordersTitle}>Presiding</TableCell>
                <TableCell className={classes.noBordersTitle}>Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData?.length > 0 ? (
                filteredData.map((row, index) => (
                  <TableRow key={index} className={classes.tableRow}>
                  <TableCell className={classes.noBorders}>{(page) * rowsPerPage +(index + 1)}</TableCell>
                  <TableCell className={classes.noBorders}>{row?.bench_number}</TableCell>
                  <TableCell className={classes.noBorders}>{row?.court_number}</TableCell>
                  <TableCell className={classes.noBorders}>{formatDate(row?.listing_date_timestamp)}</TableCell>
                  <TableCell className={classes.noBorders}>{row?.judges?.[0]?.name}</TableCell>
                  <TableCell className={classes.noBorders}>{row?.presiding_judge?.name}</TableCell>
                  <TableCell className={classes.noBorders}>{row?.presiding_judge?.name}</TableCell>
                  <TableCell className={classes.noBorders} style={{cursor: 'pointer'}} onClick={()=>handleDeleteBench(row?.id)}><DeleteOutline/></TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} className={classes.noBorders} align="center">
                    No Bench on this Listing Date
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={countPagination}
            page={page}
            onChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[]} 
            // onRowsPerPageChange={handleChangeRowsPerPage}
            className={classes.pagination}
          />
        </>
      )}
    </div>
  );
};

export default BenchTable;

