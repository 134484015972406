import React, { useEffect, useState } from 'react';
import {
  Table, TableBody, Tabs,Tab, TableCell, Grid, Box, TableContainer, TableHead, TableRow, TextField, Button, Paper, IconButton, TablePagination,makeStyles,
  Typography, MenuItem, FormControl, InputLabel, Select
} from '@material-ui/core';
import { DeleteOutline, PlusOne, Print, Search } from '@material-ui/icons';
import instance from 'api/globalaxios';
import { format } from 'date-fns';
import CircularCenteredLoader from 'features/common/CircularCenteredLoader';
import Swal from 'sweetalert2';
import { formatDate } from 'utils';
import DateRangePickerTDSAT from 'features/Tdsat/DateRangePickerTDSAT';
import DatePickerTDSAT from 'features/Tdsat/components/DatePickerTDSAT';
import fileDownload from 'js-file-download';

const useStyles = makeStyles((theme) => ({
  root: {
		padding: '1.5rem',
    width: '100%',
    boxSizing: 'border-box',
	},
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  searchField: {
    marginRight: theme.spacing(1),
    flexGrow: 1,
    borderRadius: '8px',
  },
  table: {
    minWidth: 650,
    width: '100%',
    maxWidth: '100%'
  },
  noDefect: {
    color: 'green',
  },
  pagination: {
    marginTop: theme.spacing(2),
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  datePickers: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
  },
  noBorders: {
    border: 'none',
    fontSize: '15px',
    
  },
  noBordersUploaded: {
    border: 'none',
    fontSize: '15px',
    // background: '#E8FFE2'
  },
  noBordersTitle: {
    border: 'none',
    fontSize: '15px',
    fontFamily: 'Satoshi-Bold',
  },
  buttonPrint: {
    borderRadius: '8px',
    border: '1px solid var(--BG-Color-1, #AAAFB9)',
    background: '#fff'
  },
  tableRow: {
    '& tr': {
      marginBottom: '8px !important'  
    },
    '& th, & td': { 
      padding: '26px !important',  
    },
    border: '1px solid #E5E5E5',
    background: '#fff',
  },
  tabContainer: {
    marginBottom: theme.spacing(2),
  },
  titleTop: {
    fontSize: '20px',
    fontWeight: 700,
  }
}));

const OrderTable = ({onFileNewNotice}) => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [countPagination, setCountPagination] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [formData,setFormData] = useState({date: null});
  const [loading, setLoading] = useState(false); 
  const [benchNature, setBenchNature] = useState('');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchScrutinyReportData(searchTerm, startDate, endDate, newPage + 1); // Update data on page change
  };

  const handlePrintReport = async () => {
    if (formData.date && (startDate || endDate)) {
      Swal.fire({
        title: "Error",
        text: "Please select either an order date or a date range, not both.",
        icon: "error"
      });
      return;
    }

     // Check if none of `order_date` or `DateRange` is selected
     if (!formData.date && (!startDate || !endDate)) {
      Swal.fire({
        title: "Error",
        text: "Please select either an order date or a date range.",
        icon: "error"
      });
      return;
    }
    try {
      let url = '';
    
      // Construct the API request URL based on the selected date option
      if (formData.date) {
        const formattedOrderDate = format(formData.date, 'dd-MM-yyyy');
        url = `tdsat/order_report/generate?type=main_cases&order_date=${formattedOrderDate}`;
      } else if (startDate && endDate) {
        const formattedStartDate = format(startDate, 'dd-MM-yyyy');
        const formattedEndDate = format(endDate, 'dd-MM-yyyy');
        url = `tdsat/order_report/generate?type=main_cases&start_date=${formattedStartDate}&end_date=${formattedEndDate}`;
      }
  
      // Make the API request with the constructed URL
      const response = await instance.get(url,{responseType: "blob"});
      fileDownload(response.data, `OrderReport.pdf`);
      Swal.fire({
        title: "Print Slip",
        text: "Downloaded Report Successfully",
        icon: "success"
      });
    }catch (error){
        console.error("There was an error fetching the case data!", error);
    }
  };

  const handleSubmit = () => {
    // Check if both or neither `order_date` and `DateRange` are selected
    if (formData.date && (startDate || endDate)) {
      Swal.fire({
        title: "Error",
        text: "Please select either an order date or a date range, not both.",
        icon: "error"
      });
      return;
    }

     // Check if none of `order_date` or `DateRange` is selected
     if (!formData.date && (!startDate || !endDate)) {
      Swal.fire({
        title: "Error",
        text: "Please select either an order date or a date range.",
        icon: "error"
      });
      return;
    }
    fetchScrutinyReportData(searchTerm, startDate, endDate); // Fetch data on Submit button click
  };

  const fetchScrutinyReportData = async (searchText = '', startDate = null, endDate = null, page = 1) => {
    setLoading(true); 
    try {
      const params = {
        from_date: startDate ? format(startDate, 'dd-MM-yyyy') : '',
        to_date : endDate ? format(endDate, 'dd-MM-yyyy') : '',
        order_date: formData?.date ? format(formData?.date, 'dd-MM-yyyy') : '',
        bench_nature: benchNature,
        page: page > 1 ? page : 1,
      };
      const response = await instance.get('/tdsat/filter-order/', { params });
      setFilteredData(response?.data?.results || []);
      setCountPagination(response?.data?.count);
    } catch (error) {
      console.error('There was an error fetching the case data!', error);
    }
    setLoading(false);
  };
  
  const handleDateInput = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      'date': value,
    }));
  }

  return (
    <div className={classes.root}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Grid container alignItems="center" style={{justifyContent: 'space-between'}}>
          <Grid item>
            <Typography className={classes.titleTop}>Daily Order</Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={onFileNewNotice}
              style={{ marginTop: '20px', borderRadius: '8px' }}
              endIcon={'+'}
            >
              Create Order
            </Button>
          </Grid>
        </Grid>
      </Box>
      <div style={{ display: 'flex', gap: '10px', marginBottom: '20px', marginTop: '40px' }}>
        {/* Date Range Picker */}
        <div className={classes.datePickers}>
          <DateRangePickerTDSAT
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        </div>

        <FormControl margin="none" variant="outlined">
          <DatePickerTDSAT
            date={formData.date}
            setDate={handleDateInput}
            label={'Order Date'}
          />
        </FormControl>

        <FormControl style={{ minWidth: 200 }}  variant='outlined'>
          <InputLabel>Bench Nature</InputLabel>
          <Select
            value={benchNature}
            onChange={(e) => setBenchNature(e.target.value)}
          >
            <MenuItem value="single_bench">Single Bench</MenuItem>
            <MenuItem value="division_bench">Division Bench</MenuItem>
            <MenuItem value="full_bench">Full Bench</MenuItem>
            <MenuItem value="registrar">Registrar</MenuItem>
          </Select>
        </FormControl>

        {/* Submit Button */}
        <Button onClick={handleSubmit} style={{ marginLeft: '10px' }} className={classes.buttonPrint}>
          Submit
        </Button>

        {/* Print Button */}
        <Button onClick={handlePrintReport} style={{ marginLeft: '10px' }} className={classes.buttonPrint}>
          <Print /> Print Report
        </Button>
      </div>
      
      {loading ? (
        <div className={classes.loader}>
          <CircularCenteredLoader />
        </div>
      ) : (
        <>
          <TableContainer component={Paper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableRow}>
                <TableCell className={classes.noBordersTitle}>Case Number</TableCell>
                <TableCell className={classes.noBordersTitle}>Party Details</TableCell>
                <TableCell className={classes.noBordersTitle}>Order Date</TableCell>
                <TableCell className={classes.noBordersTitle}>Order Uploaded on Website</TableCell>
                {/* <TableCell className={classes.noBordersTitle}>Action</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData?.length > 0 ? (
                filteredData.map((row, index) => (
                  <TableRow key={index} className={classes.tableRow}>
                    <TableCell className={classes.noBorders}>{row?.main_case?.case_number}</TableCell>
                    <TableCell className={classes.noBorders}>{row?.main_case?.name}</TableCell>
                    <TableCell className={classes.noBorders}>{row?.order_date}</TableCell>
                    <TableCell className={classes.noBordersUploaded}>{row?.order_uploaded ? "Yes" : "No"}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} className={classes.noBorders} align="center">
                    No Daily Orders
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={countPagination}
            page={page}
            onChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[]}
            // onRowsPerPageChange={handleChangeRowsPerPage}
            className={classes.pagination}
          />
        </>
      )}
    </div>
  );
};

export default OrderTable;

