import React, { useEffect, useState } from 'react';
import {
  Table, TableBody, Tabs,Tab, TableCell, Grid, Box, TableContainer, TableHead, TableRow, TextField, Button, Paper, IconButton, TablePagination,makeStyles,
  Typography
} from '@material-ui/core';
import { Print, Search } from '@material-ui/icons';
import instance from 'api/globalaxios';
import { format } from 'date-fns';
import CircularCenteredLoader from 'features/common/CircularCenteredLoader';
import Swal from 'sweetalert2';
import { formatDate } from 'utils';
import DateRangePickerTDSAT from 'features/Tdsat/DateRangePickerTDSAT';
import fileDownload from 'js-file-download';
import { viewNotice } from 'features/Tdsat/API/TdsatApi';
import { useSnackbar } from "contexts/SnackbarContext";

const useStyles = makeStyles((theme) => ({
  root: {
		padding: '1.5rem',
    width: '100%',
    boxSizing: 'border-box',
	},
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  searchField: {
    marginRight: theme.spacing(1),
    flexGrow: 1,
    borderRadius: '8px',
  },
  table: {
    minWidth: 650,
    width: '100%',
    maxWidth: '100%'
  },
  noDefect: {
    color: 'green',
  },
  pagination: {
    marginTop: theme.spacing(2),
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  datePickers: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    flexGrow: 1
  },
  noBorders: {
    border: 'none',
    fontSize: '15px',
    
  },
  noBordersTitle: {
    border: 'none',
    fontSize: '15px',
    fontFamily: 'Satoshi-Bold',
  },
  buttonPrint: {
    borderRadius: '8px',
    border: '1px solid var(--BG-Color-1, #AAAFB9)',
    background: '#fff'
  },
  tableRow: {
    '& tr': {
      marginBottom: '8px !important'  
    },
    '& th, & td': { 
      padding: '26px !important',  
    },
    border: '1px solid #E5E5E5',
    background: '#fff',
  },
  tabContainer: {
    marginBottom: theme.spacing(2),
  },
  titleTop: {
    fontSize: '20px',
    fontWeight: 700,
  }
}));

const NoticeTable = ({onFileNewNotice}) => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [countPagination, setCountPagination] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loading, setLoading] = useState(false); 
  const { showSnackbar } = useSnackbar();

  const tabTypes = ['notice', 'dasti_notice', 'ea/ma/other'];
  const tabTypesButton = ['Fresh Notice', 'Dasti Notice', 'EA/MA/Other Notice'];

  useEffect(() => {
    fetchScrutinyReportData();
  },[activeTab])

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setPage(0); // Reset page when tab changes
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchScrutinyReportData(searchTerm, startDate, endDate, newPage + 1); // Update data on page change
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handlePrintReport = async () => {
    if (!startDate && !endDate) {
      Swal.fire({
        title: "Error",
        text: "Please select start date and end date and then Submit.",
        icon: "error"
      });
      return;
    }
    try {
      const response = await instance.get(`tdsat/notices_report/generate?type=${tabTypes[activeTab]}&start_date=${format(startDate, 'dd-MM-yyyy')}&end_date=${format(endDate, 'dd-MM-yyyy')}`, {responseType: "blob"});
      fileDownload(
        response.data,
        `NoticeReport.pdf`
      );
      Swal.fire({
          title: "Print Slip",
          text: "Downloaded Report Successfully",
          icon: "success"
        });
    }catch (error){
        console.error("There was an error fetching the case data!", error);
    }
  };

  const handleSubmit = () => {
    // if (!startDate && !endDate) {
    //   Swal.fire({
    //     title: "Error",
    //     text: "Please select range of date and then submit",
    //     icon: "error"
    //   });
    //   return;
    // }
    fetchScrutinyReportData(searchTerm, startDate, endDate); // Fetch data on Submit button click
  };

  const fetchScrutinyReportData = async (searchText = '', startDate = null, endDate = null, page = 1) => {
    setLoading(true); 
    try {
      const params = {
        type: tabTypes[activeTab],
        start_date: startDate ? format(startDate, 'dd-MM-yyyy') : '',
        end_date: endDate ? format(endDate, 'dd-MM-yyyy') : '',
        page: page > 1 ? page : 1,
      };
      const response = await instance.get('/tdsat/notices/', { params });
      setFilteredData(response?.data?.results || []);
      setCountPagination(response?.data?.count);
    } catch (error) {
      console.error('There was an error fetching the case data!', error);
    }
    setLoading(false);
  };

  const handleViewNotice = async (noticeId) => {
		try {
			const response = await viewNotice(noticeId);
      console.log(response,'response')
			const link = response.data?.pre_signed_url;
			if (link) {
				window.open(link, '_blank');
			} else {
        showSnackbar(response?.data?.error, 'error');
				console.error("Link not found in response");
			}
		} catch (error) {
      if(error?.response?.status === 400){
        showSnackbar(error?.response?.data?.error, 'error');
    }
			console.error("Failed to fetch the notice link", error);
		}
	};


  return (
    <div className={classes.root}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Grid container alignItems="center" style={{justifyContent: 'space-between'}}>
          <Grid item>
            <Typography className={classes.titleTop}>Report of Order Notice at a Glance</Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={onFileNewNotice}
              style={{ marginTop: '20px', borderRadius: '8px' }}
              endIcon={'+'}
            >
              {tabTypesButton[activeTab]}
            </Button> 
          </Grid>
        </Grid>
      </Box>
      <div className={classes.tabContainer}>
        <Tabs value={activeTab} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
          <Tab label="Notice" />
          <Tab label="Dasti Notice" />
          <Tab label="EA/MA/Other" />
        </Tabs>
      </div>
      <div className={classes.searchContainer}>
        {/* Date Range Picker */}
        <div className={classes.datePickers}>
          <DateRangePickerTDSAT
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        </div>

        {/* Submit Button */}
        <Button onClick={handleSubmit} style={{ marginLeft: '10px' }} className={classes.buttonPrint}>
          Submit
        </Button>

        {/* Print Button */}
        <Button onClick={handlePrintReport} style={{ marginLeft: '10px' }} className={classes.buttonPrint}>
          <Print /> Print Report
        </Button>
      </div>
      
      {loading ? (
        <div className={classes.loader}>
          <CircularCenteredLoader />
        </div>
      ) : (
        <>
          <TableContainer component={Paper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableRow}>
                <TableCell className={classes.noBordersTitle}>Sr. No.</TableCell>
                <TableCell className={classes.noBordersTitle}>Date</TableCell>
                <TableCell className={classes.noBordersTitle}>Diary No.</TableCell>
                <TableCell className={classes.noBordersTitle}>Case No.</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
               {filteredData?.length > 0 ? (
                filteredData.map((row, index) => (
                  <TableRow key={index} className={classes.tableRow}>
                  <TableCell className={classes.noBorders}>{(page) * rowsPerPage +(index + 1)}</TableCell>
                  <TableCell className={classes.noBorders}>{formatDate(row?.listing_date_timestamp)}</TableCell>
                  <TableCell className={classes.noBorders}>{row?.main_case?.diary_number}</TableCell>
                  <TableCell style={{color: '#4a3bff', cursor: 'pointer'}} className={classes.noBorders} onClick={()=>handleViewNotice(row?.id)}>{row?.main_case?.case_number}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} className={classes.noBorders} align="center">
                    No Notice on this date range
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={countPagination}
            page={page}
            onChangePage={handleChangePage}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[]}
            // onRowsPerPageChange={handleChangeRowsPerPage}
            className={classes.pagination}
          />
        </>
      )}
    </div>
  );
};

export default NoticeTable;

