import React, { useEffect, useState } from 'react';
import MemoOfParties from './MemoOfParties';
import FileUploadScreen from './FileUploadScreen';
import { Container, Stepper, Step, StepLabel, Box, Button } from '@material-ui/core';
import FilingDetails from './FilingDetails';
import WaitingScreen from './WaitingScreen';
import SuccessScreenTdsat from './SuccessScreenTdsat';
import instance from 'api/globalaxios';
import { memoOfPartiesConfig, memoOfPartiesConfigRight } from './formConfigs';
import { createReciepts } from './ApplicationFillingForm';

export async function getCaseTypes() {
  return await instance({
    method: "get",
    url: "/tdsat/case-types/?type=MainCase",
  });
}

export async function deleteParty(partyId) {
  return await instance({
    method: "delete",
    url: "/tdsat/party/",
    data: {
      id: partyId
    }
  });
}

export async function createIntervenorParty(data) {
  return await instance({
    method: "post",
    url: "/tdsat/create_party/",
    data: data
  });
}

const MainCase = ({draftCase, setSelected}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [formValuesClaimant, setFormValuesClaimant] = useState({type: 'Select'});
  const [formValuesRespondent, setFormValuesRespondent] = useState({type: 'Respondent(s)'});
  const [mainCaseUpdate, setMainCaseUpdate] = useState({})
  const [mainCaseCreated, setMainCaseCreated] = useState(false);
  const [mainCaseId, setMainCaseId] = useState(null);
  const [claimantParties, setClaimantParties] = useState([]);
  const [respondentParties, setRespondentParties] = useState([]);
  const [formDetails, setFormDetails] = useState({})
  const [selectedAmount, setSelectedAmount] = useState('');
  const [stepData, setStepData] = useState({}); // To cache data for each step
  const [caseTypes, setCaseTypes] = useState([]);
  const [eRecieptData, setERecieptData] = useState({content_type: 'MainCase'});

  const [errors,setErrors] = useState({})
  const [errorsClaimant,setErrorsClaimant] = useState({})
  const [errorsRespondent,setErrorsRespondent] = useState({})

  useEffect(()=>{
    console.log('draft',draftCase);
    if(draftCase){
      const current_step = Object.values(currentStepMapping).indexOf(draftCase.current_step)
      setActiveStep(current_step);
      setMainCaseUpdate(draftCase);
      setMainCaseCreated(true);
      setMainCaseId(draftCase.id);
      if(current_step === 2){
        const filing_details = {
          case_no_connected: draftCase?.case_no_connected,
          case_type_new: caseTypes.find(caseType => caseType.code === draftCase?.case_type_new)?.id || '',
          case_year_connected: draftCase?.case_year_connected,
          court_org_name_connected: draftCase?.court_org_name_connected,
          case_no_impunged_order_direction: draftCase?.case_no_impunged_order_direction,
          decision_connected: draftCase?.decision_connected,
          decision_date: draftCase?.decision_date,
          impunged_order_direction_notice_connected: draftCase?.impunged_order_direction_notice_connected,
          is_government_party: draftCase?.is_government_party ? "Yes" : "No",
          // is_related_to_previous_case: draftCase?.is_related_to_previous_case ? "Yes" : "No",
          name: draftCase?.name,
        }
        setStepData((prevStepData) => ({
          ...prevStepData,
          1 : filing_details 
        }));
      }
    }
  },[])

  useEffect(() => {
    getCaseTypes().then((res) => {
      setCaseTypes(res.data);
    });
  },[])

  const handleFieldChangeClaimant = (keyId, value) => {
    setFormValuesClaimant(prevState => ({
          ...prevState,
          [keyId]: value
      }));
  };

  const handleFieldChangeRespondent = (keyId, value) => {
    setFormValuesRespondent(prevState => ({
          ...prevState,
          [keyId]: value
      }));
  };

  const steps = ['Memo of Parties', 'Filing Details', 'File Upload'];
  const currentStepMapping = {
    0: 'memo_of_parties',
    1: 'filing_details',
    2: 'file_upload',
    3: 'submitted'
  };

  const validateForm = (currentStep) => {
    let tempErrors = {};
  
    if (currentStep === 2) {
      // Validate fields specific to step 3
      if (!eRecieptData.receipt_number) {
        tempErrors.receipt_number = 'This field is required';
      }
      if (!formDetails.agree) {
        tempErrors.agree = 'You must agree before submitting';
      }
    }
    // Add validations for other steps if needed
    if(currentStep === 1){
      if (!formDetails.case_type_new) {
        tempErrors.case_type_new = 'Please select a Case Type';
      }
      if (!formDetails.is_government_party) {
        tempErrors.is_government_party = 'This is field is required.';
      }
      if (!formDetails.decision_date) {
        tempErrors.decision_date = 'This is field is required.';
      }
      if (!formDetails.case_no_impunged_order_direction) {
        tempErrors.case_no_impunged_order_direction = 'This is field is required.';
      }
      const selectedCaseType = caseTypes.find(caseType => caseType.id === formDetails['case_type_new'])?.description;
      if (selectedCaseType === 'Execution Application'){
        if (!formDetails.case_year_connected) {
          tempErrors.case_year_connected = 'This is field is required.';
        }
        if (!formDetails.case_no_connected) {
          tempErrors.case_no_connected = 'This is field is required.';
        }
      }
    }
    return tempErrors;
  };

  const handleNext = async () => {
    if(!mainCaseId){
      alert("Please add Party Details First")
      return;
    }
    if(mainCaseUpdate?.claimant_parties?.length === 0){
      alert("Please add atleast one Petitioner/Appellant Details.");
      return;
    }
    if(mainCaseUpdate?.respondent_parties?.length === 0){
      alert("Please add atleast one Repondent Details.");
      return;
    }
    const formErrors = validateForm(activeStep);
    if (Object.keys(formErrors).length === 0) {
      try {
        setStepData((prevStepData) => ({
          ...prevStepData,
          [activeStep]: formDetails // Cache the current step's form details
        }));
        if (activeStep === 2) { // Replace `lastStepIndex` with the actual index of the last step
          await createReciepts(eRecieptData);
        }
        await instance.patch(`tdsat/main-cases/?id=${mainCaseId}`, {
          ...formDetails,
          current_step: currentStepMapping[activeStep + 1],
          is_draft: currentStepMapping[activeStep + 1] === 'submitted' ? false : true
        });
  
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } catch (error) {
        console.error('Error updating current step:', error, error.response);
        // alert("Main case with this case number already exists");
      }
    }else {
      setErrors(formErrors);
    }
  };
  
  const updateMainCase =  (keyId, value) => {
    setFormDetails(prevState => ({
        ...prevState,
        [keyId]: value
    }));
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      const prevStep = prevActiveStep - 1;

      // Fetch the data from cache
      if (stepData[prevStep]) {
        setFormDetails(stepData[prevStep]);
      }

      return prevStep;
    });
  };

  const validateFormClaimant = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\d{10}$/;
    const newErrors = {};
    memoOfPartiesConfig.sections[0].fields.forEach(field => {
      const valueClaimant = formValuesClaimant[field.keyId];
      if (field.required && !valueClaimant) {
        newErrors[field.keyId] = "This field is required";
      }
      if (field.keyId === 'email' && valueClaimant && !emailRegex.test(valueClaimant)) {
        newErrors[field.keyId] = 'Please enter a valid email address';
      }
      if (field.keyId === 'phone_number' && valueClaimant && !phoneRegex.test(valueClaimant)) {
        newErrors[field.keyId] = 'Phone number must contain 10 digits only';
      }
      if (field.keyId === 'alt_phone_number' && valueClaimant && !phoneRegex.test(valueClaimant)) {
        newErrors[field.keyId] = 'Phone number must contain 10 digits only';
      }
    });
    setErrorsClaimant(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateFormRespondent = () => {
    
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\d{10}$/;

    const newErrors = {};
    memoOfPartiesConfigRight.sections[0].fields.forEach(field => {
      const valueRespondent = formValuesRespondent[field.keyId];
      if (field.required && !valueRespondent) {
        newErrors[field.keyId] = "This field is required";
      }
      if (field.keyId === 'email' && valueRespondent && !emailRegex.test(valueRespondent)) {
        newErrors[field.keyId] = 'Please enter a valid email address';
      }
      if (field.keyId === 'phone_number' && valueRespondent && !phoneRegex.test(valueRespondent)) {
        newErrors[field.keyId] = 'Phone number must contain 10 digits only';
      }
      if (field.keyId === 'alt_phone_number' && valueRespondent && !phoneRegex.test(valueRespondent)) {
        newErrors[field.keyId] = 'Phone number must contain 10 digits only';
      }
    });
    setErrorsRespondent(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const createPartyIndividual = async () => {
    if(validateFormClaimant()){
        try {
        const response = await instance.post('tdsat/party/', formValuesClaimant);
        setFormValuesClaimant({ type: formValuesClaimant.type});
        const partyId = response.data.id; 
        if (partyId) {
          const newClaimantParties = [...claimantParties, partyId];
          setClaimantParties(newClaimantParties);
          if (!mainCaseCreated) {
            const mainCaseResponse = await instance.post('tdsat/main-cases/', {claimant_parties: newClaimantParties });
            setMainCaseCreated(true);
            setMainCaseId(mainCaseResponse.data.id);
            setMainCaseUpdate(mainCaseResponse?.data);
        } else {
            const mainCaseResponse = await instance.patch(`tdsat/main-cases/?id=${mainCaseId}`, {claimant_parties: newClaimantParties });
            setMainCaseUpdate(mainCaseResponse?.data);
        }
        }
      } catch (error) {
          console.error('API error:', error);
      }
    }else {
      console.log('Form validation failed');
    }
  }

  const createRespondentIndividual = async () => {
    if(validateFormRespondent()){
      try {
          const response = await instance.post('tdsat/party/', formValuesRespondent);
          setFormValuesRespondent({type: 'Respondent(s)'});
          const partyId = response.data.id; 
          if (partyId) {
            const newRespondentParties = [...respondentParties, partyId];
            setRespondentParties(newRespondentParties);
            if (!mainCaseCreated) {
              const mainCaseResponse = await instance.post('tdsat/main-cases/', {respondent_parties: newRespondentParties });
              setMainCaseCreated(true)
              setMainCaseId(mainCaseResponse.data.id);
              setMainCaseUpdate(mainCaseResponse?.data);
          } else {
              const mainCaseResponse = await instance.patch(`tdsat/main-cases/?id=${mainCaseId}`, {respondent_parties: newRespondentParties });
              setMainCaseUpdate(mainCaseResponse?.data);
          }
        }
      } catch (error) {
          console.error('API error:', error);
      }
    }else {
      console.log('Form validation failed');
    }
  }

  const handleDeleteClaimant = (partyId) => {
    {claimantParties.length === 1 && setFormValuesClaimant({type: 'Select'})}
    deleteParty(partyId)
      .then((res) => {
          setMainCaseUpdate(prevState => {
            const updatedClaimants = prevState.claimant_parties.filter(claimant => claimant.id !== partyId);
            const updatedClaimantPartyIds = updatedClaimants.map(party => party.id);
            setClaimantParties(updatedClaimantPartyIds);
            return {
              ...prevState,
              claimant_parties: updatedClaimants
            };
          }); 
      })
      .catch((error) => {
        console.error('Error while deleting claimant:', error);
      });
  };

  const handleDeleteRespondent = (partyId) => {
    deleteParty(partyId)
      .then((res) => {
          setMainCaseUpdate(prevState => {
            const updatedRespondent = prevState.respondent_parties.filter(claimant => claimant.id !== partyId);
            const updatedRespondentPartyIds = updatedRespondent.map(party => party.id);
            setRespondentParties(updatedRespondentPartyIds);
            return {
              ...prevState,
              respondent_parties: updatedRespondent
            };
          }); 
      })
      .catch((error) => {
        console.error('Error while deleting claimant:', error);
      });
  }

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <MemoOfParties 
                handleFieldChangeClaimant={handleFieldChangeClaimant} 
                handleFieldChangeRespondent={handleFieldChangeRespondent}
                formValuesClaimant={formValuesClaimant} 
                formValuesRespondent={formValuesRespondent} 
                createPartyIndividual={createPartyIndividual}
                createRespondentIndividual={createRespondentIndividual}
                mainCaseUpdate={mainCaseUpdate}
                errorsClaimant={errorsClaimant}
                errorsRespondent={errorsRespondent}
                handleDeleteClaimant={handleDeleteClaimant}
                handleDeleteRespondent={handleDeleteRespondent}
              />;
      case 1:
        return <FilingDetails
                  mainCaseUpdate={mainCaseUpdate}
                  formDetails={formDetails}
                  updateMainCase={updateMainCase}
                  setSelectedAmount={setSelectedAmount}
                  caseTypes={caseTypes}
                  errors={errors}
              />;
      case 2:
        return <FileUploadScreen
                  mainCaseUpdate={mainCaseUpdate}
                  formDetails={formDetails}
                  updateMainCase={updateMainCase}
                  errors={errors}
                  selectedAmount={selectedAmount}
                  setERecieptData={setERecieptData}
                  eRecieptData={eRecieptData}
                />;
      case 3:
        return <SuccessScreenTdsat diaryNumber={mainCaseUpdate?.diary_number} date={mainCaseUpdate?.draft_creation_date_time} isApplicationRedirection={true} setSelected={setSelected}/>;
      default:
        return <WaitingScreen/>;
    }
  };

  return (
    <Container>
      <Box mt={4}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={index}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box mt={3} mb={3}>
          {getStepContent(activeStep)}
          {(activeStep !== 3) && (
            <Box mt={2}>
            <Button disabled={activeStep === 0} onClick={handleBack}>
              Previous
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              style={{ marginLeft: '10px' }}
            >
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
          </Box>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default MainCase;
